import { doc, getDoc, Timestamp, collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../firebase-private";
import { useEffect, useState } from "react";
import { useSafeAsync } from "../useSafeAsync";
import LoadingGuard from "../loadingGuard";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, Link, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Deployment from "../types/deployments";
import { Add, Edit, ExpandMore, FileCopy, FolderOpen, People } from "@mui/icons-material";
import Mission from "../types/missions";



async function LoadDeployments() {
  const deploymentsColl = collection(db,'deploymenty');
  const deploymentsSnap = await getDocs(deploymentsColl);

  var deployments:Deployment[] = [];
  deploymentsSnap.forEach((doc)=>{
    const data = doc.data();

    data.datum_do = new Timestamp(data.datum_do.seconds,data.datum_do.nanoseconds).toDate()
    data.datum_od = new Timestamp(data.datum_od.seconds,data.datum_od.nanoseconds).toDate()

    deployments.push({id:doc.id,nazev: data.nazev, mapa: data.mapa, disk: data.disk, datum_od: data.datum_od, datum_do: data.datum_do, oficilani: data.oficialni,});
  })
  
  return deployments;
}

async function LoadMissions({deployment}:{deployment:string}) {
  const date = new Date();
  const missionsColl = collection(db, 'deploymenty', deployment, 'mise');

  const missionsSnapshot = await getDocs( query(missionsColl,orderBy("datum", "desc")));
  if (!missionsSnapshot.empty) {
    return missionsSnapshot.docs.map((doc) => {
      const data = doc.data();

      data.datum = new Timestamp(data.datum.seconds,data.datum.nanoseconds).toDate()

      return {
        id:doc.id,
        nazev: data.nazev,
        mapa: data.mapa,
        image: data.image,
        gear: data.gear,
        briefing: data.briefing,
        povinne: data.povinne,
        povolene: data.povolene,
        zakazane: data.zakazane,
        dodatecne: data.dodatecne,
        datum: data.datum,
        sloty: data.sloty,
        dokoncena: data.dokoncena,
        deployment: data.deployment
      }
    } );
  }

  return [];
}

async function LoadSoloMissions() {
  const date = new Date(); 
  const missionsColl = query(collection(db, 'mise'), orderBy("datum", "desc"))

  const missionsSnapshot = await getDocs(query(missionsColl));
  if (!missionsSnapshot.empty) {
    return missionsSnapshot.docs.map((doc) => {
      const data = doc.data();

      data.datum = new Timestamp(data.datum.seconds,data.datum.nanoseconds).toDate()
      data.datum_ingame = new Timestamp(data.datum_ingame.seconds,data.datum_ingame.nanoseconds).toDate()

      return {
        id:doc.id,
        nazev: data.nazev,
        mapa: data.mapa,
        image: data.image,
        gear: data.gear,
        briefing: data.briefing,
        typ: data.typ,
        valecne_pravo: data.valecne_pravo,
        povinne: data.povinne,
        povolene: data.povolene,
        zakazane: data.zakazane,
        dodatecne: data.dodatecne,
        datum: data.datum,
        datum_ingame: data.datum_ingame,
        sloty: data.sloty,
        dokoncena: (data.dokoncena)?data.dokoncena:((data.stav === "nezahajena")?"ne":"ano")
      }
    } );
  }
  
  return [];
}

export function ListDeployments({step,setStep}:{step:number, setStep:Function}) {
  const isNotPhone = useMediaQuery('(min-width:600px)');
  const [deploymentsCall, deploymentsState] = useSafeAsync<any,Deployment[]>(LoadDeployments);
  const [formSent,setFormSent] = useState(false);

  useEffect(()=>{
    deploymentsCall({});
  },[])
  
  return(
    <LoadingGuard state={deploymentsState}>
      {deploymentsState.value &&
        <Stack direction={"column"} spacing={1} m={1}>
          {deploymentsState.value.map((one:Deployment)=>{

            return (
              <Grid key={one.id} container direction={"row"} alignItems={"center"} component={Paper} px={1} py={0.5} spacing={(isNotPhone)?1:0.75}>
                <Grid item xs={12} md={2}>
                    <Stack direction={(isNotPhone)?"column":"row"} spacing={(isNotPhone)?0:2}>
                      <Typography variant="body2">{one.datum_od.toLocaleDateString("cs-CZ")}</Typography>
                      <Typography variant="body2">{one.datum_do.toLocaleDateString("cs-CZ")}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}><Link href={`/admin/l/missions?id=${one.id}`}><Typography variant="h5">{one.nazev}</Typography></Link></Grid>
                  
                  <Grid item xs={3} md={1}>
                    <Link href={one.disk} target="__blank" title="Google Disk" >
                      <IconButton><FolderOpen /></IconButton>
                    </Link>
                  </Grid>
                  
                  <Grid item xs={9} md={3}>
                    <Stack direction={"row"} spacing={1}>
                      <Divider orientation="vertical" flexItem />

                      <Link href={"/admin/d/dedit?id="+one.id} title="Upravit" >
                        <IconButton><Edit /></IconButton>
                      </Link>
                      
                      <Link href={"/admin/d/add?id="+one.id} title="Přidat misi" >
                        <IconButton><Add /></IconButton>
                      </Link>
                    </Stack>
                  </Grid>
              </Grid>
            )
          })
          }
        </Stack>
      }
    </LoadingGuard>
  )
}


export function ListMissions({step,setStep,id}:{step:number, setStep:Function,id?:string|null}) {
  const isNotPhone = useMediaQuery('(min-width:600px)');
  const [missionsCall, missionsState] = useSafeAsync<any,any>((id)?LoadMissions:LoadSoloMissions);

  const theme = useTheme();
  const now = new Date();

  useEffect(()=>{
    missionsCall((id)?{deployment:id}:{});
  },[])

  return(
    <LoadingGuard state={missionsState}>
      {missionsState.value &&
        <Stack direction={"column"} spacing={1} m={1}>
          {missionsState.value.map((one:Mission)=>{

            return (
              <Grid key={one.id} container direction={"row"} alignItems={"center"} component={Paper} px={1} py={0.5} spacing={(isNotPhone)?1:0.75}>
                <Grid item xs={12} md={2}>
                    <Stack direction={(isNotPhone)?"column":"row"} spacing={(isNotPhone)?0:2}>
                      <Typography variant="body2" color={(now > one.datum)?theme.palette.error.main:theme.palette.success.main}>{one.datum.toLocaleDateString("cs-CZ")}</Typography>
                      <Typography variant="body2">{one.datum.toLocaleDateString("cs-CZ",{weekday:"long"})} {one.datum.toLocaleTimeString("cs-CZ").slice(0,-3)}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}><Typography variant="h5">{one.nazev}</Typography></Grid>
                  
                  { one.typ &&
                  <Grid item xs={12} md={1.5}>
                    <Typography color={(one.typ === "o")?theme.palette.primary.main:((one.typ === "t")?theme.palette.info.main:theme.palette.error.main)} >{(one.typ === "o")?"Oficiální":((one.typ === "t")?"Trénink":"Neoficiální")}</Typography>
                  </Grid>
                  }
                  <Grid item xs={3} md={0.5}>
                    <Link href={one.briefing} target="__blank" title="Briefing" >
                      <IconButton><FileCopy /></IconButton>
                    </Link>
                  </Grid>
                  
                  <Grid item xs={9} md={2}>
                    <Stack direction={"row"} spacing={1}>
                      <Divider orientation="vertical" flexItem />

                      <Link href={(one.deployment)?"/admin/d/edit?mission="+one.id+"&deployment="+one.deployment:"/admin/m/edit?id="+one.id} title="Upravit" >
                        <IconButton><Edit /></IconButton>
                      </Link>
                      
                      <Link href={"/admin/m/end?mission="+one.id+((one.deployment)?"&deployment="+one.deployment:"")} title="Docházka" >
                        <IconButton><People /></IconButton>
                      </Link>
                    </Stack>
                  </Grid>
              </Grid>
            )
          })
          }
        </Stack>
      }
    </LoadingGuard>
  )
}