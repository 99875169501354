import { Autocomplete, Box, CircularProgress } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { Controller, TextFieldElement } from "react-hook-form-mui";

export interface AutocompleteOptions {
  label: string;
  value: string;
}
export type FetchDataFunction = () => Promise<AutocompleteOptions[]>;

export function AsyncAutocomplete({control, asyncFunc, inputLabel, inputName}:{control:any, asyncFunc:FetchDataFunction, inputLabel:string, inputName:string}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AutocompleteOptions[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        asyncFunc()
        .then((names)=>{setOptions(names)})
        .catch((err:any)=> {
          console.log(err)
        })
      }

    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return(
    <Controller
      name={inputName}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          id="image"
          open={open}
          onOpen={() => { setOpen(true); }}
          onClose={() => { setOpen(false); }}
          isOptionEqualToValue={(option, value) => option.value === value}
          renderOption={(props, option) => (<Box component="li" {...props}> {option.label}</Box>)}
          getOptionLabel={(option) => option.value || option || "default"}
          options={options}
          loading={loading}
          value={field.value || null}
          onChange={(_, data) => {
            if (typeof data === 'string' || data instanceof String) {
              field.onChange(data);
            } else if (data && data.value) {
              field.onChange(data.value);
            } else {
              field.onChange('');
            }
          }}
          renderInput={(params) => (
            <TextFieldElement 
            {...params}
            label={inputLabel}
            name={inputName}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            />
          )}
        />
      )}
    />
  )
}