import { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserEdit from "../components/admin/userEdit";
import UserNew from "../components/admin/userNew";
import MissionNew from "../components/admin/missionNew";
import MissionParticipation from "../components/admin/missionParticipation";
import MissionEdit from "../components/admin/missionEdit";
import DeploymentNew from "../components/admin/deploymentNew";
import DeploymentAdd from "../components/admin/deploymentAdd";
import DeploymentMissionEdit from "../components/admin/deploymentMissionEdit";
import FilesUploadPage from "../components/admin/filesUploadPage";
import DeploymentEdit from "../components/admin/deploymentEdit";
import MissionFinish from "../components/admin/missionFinish";
import NotificationsManualPage from "../components/admin/notificationsManual";
import { UserContext } from "../components/context/userContext";
import { ListDeployments, ListMissions } from "../components/admin/list";


function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function AdminPage(props:any) {
  const [step,setStep] = useState(0);
  const { adminLevel } = useContext(UserContext) as { adminLevel: Number};
  const Navigate = useNavigate();

  const {type,command} = useParams();
  const query = useQuery();

  switch (type) {
    case "c":
      if (command === "edit" && adminLevel === 1) return(<UserEdit step={step} setStep={setStep} />)
      if (command === "new" && adminLevel === 1) return(<UserNew step={step} setStep={setStep} />)
      break;

    case "m":
      if (command === "new") return(<MissionNew step={step} setStep={setStep} />)
      if (command === "edit") return(<MissionEdit step={step} setStep={setStep} id={query.get("id")} />)
      if (command === "part") return(<MissionParticipation step={step} setStep={setStep} />)
      if (command === "end") return(<MissionFinish step={step} setStep={setStep} mission={query.get("mission")} deployment={query.get("deployment")} />)
      break;

    case "d":
      if (command === "new") return(<DeploymentNew step={step} setStep={setStep} />)
      if (command === "dedit") return(<DeploymentEdit step={step} setStep={setStep} id={query.get("id")} />)
      if (command === "add") return(<DeploymentAdd step={step} setStep={setStep} id={query.get("id")} />)
      if (command === "edit") return(<DeploymentMissionEdit step={step} setStep={setStep} mission={query.get("mission")} deployment={query.get("deployment")} />)
      if (command === "part") return(<MissionParticipation step={step} setStep={setStep} />)
      if (command === "end") return(<MissionFinish step={step} setStep={setStep} mission={query.get("mission")} deployment={query.get("deployment")} />)
      break;
    
    case "l":
      if (command === "deployments") return(<ListDeployments step={step} setStep={setStep} />)
      if (command === "missions") return(<ListMissions step={step} setStep={setStep} id={query.get("id")} />)
      break;

    case "files":
      if (command === "upload") return(<FilesUploadPage step={step} setStep={setStep} />)
      break;

    case "notifications":
      if (command === "manual" && adminLevel === 1) return(<NotificationsManualPage step={step} setStep={setStep} />)
      break;

    default:
      Navigate("/admin");
      break;
  }

  return (<></>);
}