import { Fragment, useEffect, useState } from "react";
import { useSafeAsync } from "../../components/useSafeAsync";
import {db} from "../firebase-private";
import LoadingGuard from "../../components/loadingGuard";
import { Box, Button, Stack, Typography } from "@mui/material";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import { defaultRangersSlots } from "./defaultSlots";
import LoaderButton from "../loaderButton";


async function SaveParticipation(deployment:string, mission:string, sloty:any,platoon:string,slot:string,member:string,missionCall:any) {
    sloty[platoon][slot].clen = member;
  
    if (deployment !== "") {
      await setDoc(doc(db,'deploymenty',deployment,'mise',mission), {
        sloty: sloty
      },{merge:true});
      missionCall({deployment:deployment,id:mission})
    }else{
      await setDoc(doc(db,'mise',mission), {
        sloty: sloty
      },{merge:true});
      missionCall({id:mission})

    }
  }


async function LoadMissions({deploymentId}:{deploymentId:string}) {
  const missionsColl = collection(db,'deploymenty',deploymentId,'mise');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}
async function LoadDeployments() {
  const missionsColl = collection(db,'deploymenty');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}

async function LoadSoloMissions() {
  const missionsColl = collection(db,'mise');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}

async function LoadMission({deployment,id}:{id:string,deployment:string}) {
    const missionColl = doc(db,'deploymenty',deployment,'mise',id);
    const missionSnap = await getDoc(missionColl);

    if (missionSnap.exists()) {
      var obj = missionSnap.data();

      if (obj.stav !== "dokoncena") {
        const userColl = collection(db,'users');
        const userSnap = await getDocs(userColl);

        var users:any[] = [{id:"",label:"Nikdo"}];
        userSnap.forEach((doc)=>{
          users.push({id:doc.data().clen,label:doc.data().clen});
        })
        obj.members = users;
      }

      //pokud je Rangers ORBAT
    if (Object.keys(obj.sloty).includes("MISFIT")){
      const order = Object.keys(defaultRangersSlots);
      
      Object.keys(obj.sloty).map((plat:any)=>{
        if (obj.sloty[plat] ) { //pokud existuje platoon
          if (defaultRangersSlots[plat as keyof typeof defaultRangersSlots]) {
            let order = Object.keys(defaultRangersSlots[plat as keyof typeof defaultRangersSlots])
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort((a:any,b:any)=>{return(order.indexOf(a) - order.indexOf(b))}) //seradit jednotlive sloty A-A-1, A-A-2, atd... - PODLE ORBAT
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }else{
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort() //seradit jednotlive sloty A-A-1, A-A-2, atd... - ABECEDNE
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }
        }
      })

      obj.sloty = Object.keys(obj.sloty).sort((a:any,b:any)=>{
        if (order.includes(a) && order.includes(b)) {
          return order.indexOf(a) - order.indexOf(b);
        }else if (!order.includes(a)) {
          return 1; // Move 'a' to the end
        }else if (!order.includes(b)) {
          return -1; // Keep 'a' before 'b'
        }
        return -1;
      }) //seradit platoony dle ORBAT a nebo je dej na konec
        .reduce(function (acc:any, key) { 
          acc[key] = obj.sloty[key];
          return acc;
        }, {});
        
    }
     
      return obj;
    
    }
  return null;
}

async function LoadSoloMission({id}:{id:string}) {
  const missionColl = doc(db,'mise',id);
  const missionSnap = await getDoc(missionColl);

  if (missionSnap.exists()) {
    var obj = missionSnap.data();

    if (obj.stav !== "dokoncena") {
      const userColl = collection(db,'users');
      const userSnap = await getDocs(userColl);

      var users:any[] = [{id:"",label:"Nikdo"}];
      userSnap.forEach((doc)=>{
        users.push({id:doc.data().clen,label:doc.data().clen});
      })
      obj.members = users;
    }

    //pokud je Rangers ORBAT
    if (Object.keys(obj.sloty).includes("MISFIT")){
      const order = Object.keys(defaultRangersSlots);
      
      Object.keys(obj.sloty).map((plat:any)=>{
        if (obj.sloty[plat] ) { //pokud existuje platoon
          if (defaultRangersSlots[plat as keyof typeof defaultRangersSlots]) {
            let order = Object.keys(defaultRangersSlots[plat as keyof typeof defaultRangersSlots])
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort((a:any,b:any)=>{return(order.indexOf(a) - order.indexOf(b))}) //seradit jednotlive sloty A-A-1, A-A-2, atd... - PODLE ORBAT
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }else{
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort() //seradit jednotlive sloty A-A-1, A-A-2, atd... - ABECEDNE
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }
        }
      })

      obj.sloty = Object.keys(obj.sloty).sort((a:any,b:any)=>{
        if (order.includes(a) && order.includes(b)) {
          return order.indexOf(a) - order.indexOf(b);
        }else if (!order.includes(a)) {
          return 1; // Move 'a' to the end
        }else if (!order.includes(b)) {
          return -1; // Keep 'a' before 'b'
        }
        return -1;
      }) //seradit platoony dle ORBAT a nebo je dej na konec
        .reduce(function (acc:any, key) { 
          acc[key] = obj.sloty[key];
          return acc;
        }, {});
        
    }

    return obj;
  
  }
  return null;
}

export default function MissionParticipation(props:any) {
  const [missionCall, missionState] = useSafeAsync<any,any>(LoadMission);
  const [deploymentsCall, deploymentsState] = useSafeAsync<any,any>(LoadDeployments);
  const [missionsCall, missionsState] = useSafeAsync<any,any>(LoadMissions);
  const [soloMissionsCall, soloMissionsState] = useSafeAsync<any,any>(LoadSoloMissions);
  const [soloMissionCall, soloMissionState] = useSafeAsync<any,any>(LoadSoloMission);
  const [mission,setMission] = useState("");
  const [soloMission,setSoloMission] = useState("");
  const [deployment,setDeployment] = useState("");
  

  useEffect(()=>{
    if(deployment === "" && mission === "" && soloMission === ""){
      deploymentsCall({deploymentId:deployment});
      soloMissionsCall({});
    }else if(deployment !== "" && mission !== ""){
      missionCall({deployment:deployment,id:mission});
    }else if(soloMission !== ""){
      soloMissionCall({id:soloMission});
    } else {
      missionsCall({deploymentId:deployment});
    }
  },[mission,deployment,soloMission])


  if (deployment === "" && mission === "" && soloMission === "") {
    return(<>
      <LoadingGuard state={deploymentsState} >
        {deploymentsState.value && soloMissionsState.value &&
        <>
        <FormContainer
          defaultValues={{}}
        >
          <Stack p={2} spacing={1}>
          <SelectElement
            label="Deployment"
            name="deployment"
            fullWidth={true}
            options={deploymentsState.value}
            onChange={(val)=>setDeployment(val)}
          />
          <SelectElement
            label="Neoficiální mise"
            name="neofiko-mise"
            fullWidth={true}
            options={soloMissionsState.value}
            onChange={(val)=>setSoloMission(val)}
          />
          </Stack>
        </FormContainer>
        </>
        }
      </LoadingGuard>
    </>);
  }else if (mission === "" && soloMission === "") {
    return(<>
      <LoadingGuard state={missionsState} >
        {missionsState.value &&
        <Box p={2}>
          <FormContainer
            defaultValues={{}}
          >
            <SelectElement
              label="Mise"
              name="mission"
              fullWidth={true}
              options={missionsState.value}
              onChange={(val)=>setMission(val)}
            />
          </FormContainer>
        </Box>
        }
      </LoadingGuard>
    </>);
  }else if(deployment === "" && mission === "") {
    return (
    <LoadingGuard state={soloMissionState} >
      {soloMissionState.value &&
      <Stack direction={"column"} p={4} spacing={2}>
        <Typography variant="body1">Název: {soloMissionState.value.nazev}</Typography>
        <Stack direction="column" spacing={1}>
        {Object.keys(soloMissionState.value.sloty).map((plat)=>{
          return (<Fragment key={plat}>
            <Typography variant="h4"  >{plat.toUpperCase()}: </Typography>
            { Object.keys(soloMissionState.value.sloty[plat]).map((key:any)=>{
                const obj = soloMissionState.value.sloty[plat][key];
                if (!obj.povoleno) return(<></>);
                return(
                <FormContainer
                  key={`${plat}-${key}`}
                  defaultValues={{member:obj.clen}}
                  onSuccess={(data:any)=>{SaveParticipation("",soloMission,soloMissionState.value.sloty,plat,key,data.member,soloMissionCall)}}
                >
                  <Stack direction={"row"}>
                    <SelectElement 
                      label={`${key}-${obj.kit}`} 
                      name="member"
                      fullWidth={true}
                      options={soloMissionState.value.members}
                    />
                    <LoaderButton type="submit" variant="outlined">Uložit!</LoaderButton>
                  </Stack>
                </FormContainer>
                )
              })
            }
          </Fragment>);
        })}
        </Stack>
      </Stack>  
      }
    </LoadingGuard>
    )
  } else {
    return(<>
      <LoadingGuard state={missionState} >
          {missionState.value &&
          <>
          <Stack direction={"column"} p={4} spacing={2}>
            <Typography variant="body1">Název: {missionState.value.nazev}</Typography>
            <Stack direction="column" spacing={1}>
            {Object.keys(missionState.value.sloty).map((plat)=>{
              return (<Fragment key={plat}>
                <Typography variant="h4" >{plat.toUpperCase()}: </Typography>
                { Object.keys(missionState.value.sloty[plat]).map((key:any)=>{
                    const obj = missionState.value.sloty[plat][key];
                    if (!obj.povoleno) return(<></>);
                    return(
                    <FormContainer
                      key={`${plat}-${key}`}
                      defaultValues={{member:obj.clen}}
                      onSuccess={(data:any)=>{SaveParticipation(deployment,mission,missionState.value.sloty,plat,key,data.member,missionCall)}}
                    >
                      <Stack direction={"row"}>
                        <SelectElement 
                          label={`${key}-${obj.kit}`} 
                          name="member"
                          fullWidth={true}
                          options={missionState.value.members}
                        />
                        <LoaderButton type="submit" variant="outlined">Uložit!</LoaderButton>
                      </Stack>
                    </FormContainer>
                    )
                  })
                }
              </Fragment>);
            })}
            </Stack>
          </Stack>  
          </>
          }
      </LoadingGuard>
    </>)
    }
  }
  
  