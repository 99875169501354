import { Add, Close } from "@mui/icons-material";
import { Stack, Box, IconButton, Button, Typography, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useFormContext, useFieldArray, TextFieldElement, CheckboxElement, SelectElement } from "react-hook-form-mui";
import { defaultRangersSlots } from "./defaultSlots";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-private";
import { useSafeAsync } from "../useSafeAsync";
import LoadingGuard from "../loadingGuard";


async function loadUsers() {
  const userColl = collection(db,'users');
  const userSnap = await getDocs(userColl);

  var users:any[] = [{id:"",label:"Nikdo"}];
  userSnap.forEach((doc)=>{
    users.push({id:doc.data().clen,label:doc.data().clen});
  })
  return users.sort((a, b) => {
    // "Nikdo" always comes first
    if (a.label === "Nikdo") return -1;
    if (b.label === "Nikdo") return 1;
  
    // Standard alphabetical sorting for all other labels
    return a.label.localeCompare(b.label);
  });
}

export function PlatoonsElements(props:any) {
  const [usersCall, usersState] = useSafeAsync<any,any>(loadUsers);
  const form = useFormContext();
  const control = form.control;
  const register = form.register;
  const [checked,setChecked] = useState(false);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "sloty", // unique name for your Field Array
  });

  useEffect(()=>{
    usersCall({});
  },[])

  if (fields.length == 0) {
    
  }
  const slots = SortRangerOrbat(props.slots);

  return(
    <LoadingGuard state={usersState} >
      {usersState.value &&
        Object.keys(slots).map((plat:any)=>
        {
          const platoon = slots[plat as keyof typeof slots];
          return(<Stack key={plat} direction={"column"}ml={3}>
            <Typography variant="h5">{plat}</Typography>
            {Object.keys(platoon).map((slot:any)=>{
              const one:any = platoon[slot as keyof typeof platoon];
              const margin = ((["A-A-1","B-A-1","C-A-1","W-A-1","S-A-1","E-A-1","H-A-1","M-A-1"].indexOf(slot) > -1)?3:((["A-1-1","A-2-1","B-1-1","B-2-1","C-1-1","C-2-1"].indexOf(slot) > -1)?2:1))
              return(<Stack key={plat+"-"+slot} direction={'row'} spacing={1} marginTop={margin} alignItems={"center"}>
                <TextField disabled={true} value={slot}>{slot}</TextField>
                <TextFieldElement name={`sloty[${plat}][${slot}].kit`} label={"Kit"}>{one.kit}</TextFieldElement>
                <SelectElement 
                  label={"Clen"}
                  name={`sloty[${plat}][${slot}].clen`}
                  options={usersState.value}
                  value={one.clen}
                  sx={{minWidth: "300px"}}
                />
                <CheckboxElement name={`sloty[${plat}][${slot}].povoleno`} checked={one.povoleno} />
              </Stack>)
            })}
          </Stack>)
        })
      }
    </LoadingGuard>
  )
}

export function SlotsElements(props:any) {
  const form = useFormContext();
  const control = form.control;
  const register = form.register;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "platoon-"+props.platoon, // unique name for your Field Array
  });

  function addSlot() {
    append({slot: "",kit: "",clen: "", povoleno:true})
    
  }
  useEffect(()=>{
    form.setValue(`sloty.${props.index}.value`,{...fields})
  },[append,fields]) 
  

  if (props.platoon === "ZEUS" && fields.length == 0) {
    append({slot: "ZEUS-1",kit: "Zeus",clen: "", povoleno:true})
    append({slot: "ZEUS-2",kit: "Zeus",clen: "", povoleno:true})
    append({slot: "ZEUS-3",kit: "Zeus",clen: "", povoleno:true})
    append({slot: "ZEUS-4",kit: "Zeus",clen: "", povoleno:true})
  }
  if (props.platoon === "NAHRADNICI" && fields.length == 0) {
    append({slot: "N-1",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-2",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-3",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-4",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-5",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-6",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-7",kit: "Náhradník",clen: "", povoleno:true})
  }
  
  


  return(<>
    <TextFieldElement name={`sloty.${props.index}.name`} label={"Název"}>{props.name}</TextFieldElement>
    <Stack direction={"column"} spacing={1} pl={3}>
      {fields.map((field:any, index) => {
        form.setValue(`sloty.${props.index}.value`,{...fields}) // Pro nacteni vychozi hodnoty
        return(<Stack key={field.id} direction={'row'} spacing={1}>
          <TextFieldElement name={`sloty.${props.index}.value[${index}].slot`} label={"Slot"} />
          <TextFieldElement name={`sloty.${props.index}.value[${index}].kit`} label={"Kit"} />
          <TextFieldElement name={`sloty.${props.index}.value[${index}].clen`} label={"Clen"} />
          <Box><IconButton onClick={()=>{remove(index)}}><Close /></IconButton></Box>
        </Stack>)
      })}
    </Stack>
    <Box><Button variant="outlined"  onClick={addSlot}><Add /> Slot</Button></Box>
  </>)
}

export function SortRangerOrbat(slots:any){
  if (Object.keys(slots).includes("MISFIT")){
    const order = Object.keys(defaultRangersSlots);
    
    Object.keys(slots).map((plat:any)=>{
      if (slots[plat] ) { //pokud existuje platoon
        if (defaultRangersSlots[plat as keyof typeof defaultRangersSlots]) {
          let order = Object.keys(defaultRangersSlots[plat as keyof typeof defaultRangersSlots])
          slots[plat] = Object.keys(slots[plat])
            .sort((a:any,b:any)=>{return(order.indexOf(a) - order.indexOf(b))}) //seradit jednotlive sloty A-A-1, A-A-2, atd... - PODLE ORBAT
            .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
              acc[key] = slots[plat][key];
              return acc;
            },  {});
        }else{
          slots[plat] = Object.keys(slots[plat])
            .sort() //seradit jednotlive sloty A-A-1, A-A-2, atd... - ABECEDNE
            .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
              acc[key] = slots[plat][key];
              return acc;
            },  {});
        }
      }
    })

    slots = Object.keys(slots).sort((a:any,b:any)=>{
      if (order.includes(a) && order.includes(b)) {
        return order.indexOf(a) - order.indexOf(b);
      }else if (!order.includes(a)) {
        return 1; // Move 'a' to the end
      }else if (!order.includes(b)) {
        return -1; // Keep 'a' before 'b'
      }
      return -1;
    }) //seradit platoony dle ORBAT a nebo je dej na konec
      .reduce(function (acc:any, key) { 
        acc[key] = slots[key];
        return acc;
      }, {});
      
  }
  return slots;
}