import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { useSafeAsync } from "../components/useSafeAsync";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../components/firebase-public";
import { CustomObjectSort } from "../components/helpers";
import LoadingGuard from "../components/loadingGuard";
import { Box, Paper, Stack, Typography } from "@mui/material";



async function LoadKurzy(kurz:any) {
  var members:any = {}
  var out:any = {}
  //nacist vsechny cleny
  const membersColl = collection(db, 'clenove');

  //offline neni tak zkouska online
  const membersSnap = await getDocs(membersColl); //pokud neni offline tak nacist ze serveru
  if (!membersSnap.empty) {
    membersSnap.forEach((obj:any)=>{
      let tmp = obj.data();
      members[obj.id]={
        hodnost: tmp.hodnost,
        hodnost_order: tmp.hodnost_order,
        kurzy: tmp.kurzy
      }
    })
  }
    
  if(Object.keys(members).length !== 0){
    var min_hodnost:undefined|number = undefined;
    //nacist kurzy podle hodnosti
    const kurzyColl = doc(db,'kurzy','podle_hodnosti');

    const kurzySnap = await getDoc(kurzyColl);
    if (kurzySnap.exists()) min_hodnost = kurzySnap.data()[kurz];
    if (typeof min_hodnost !== "undefined") {
      //filtrovat cleny na ty co to nemaji a ty co jsou instruktori
      Object.keys(members).forEach((id:any)=>{
        const one = members[id];
        if ( (one.kurzy[kurz] == 0 && one.hodnost_order >= min_hodnost!)
            || (one.kurzy[kurz] == 2)
          ) {
          out[id] = {hodnost_order: one.hodnost_order, hodnost: one.hodnost, kurz: one.kurzy[kurz]};
        }
        return null;
      }) 
      return CustomObjectSort(out,(a:any,b:any)=>{return b.hodnost_order - a.hodnost_order }); 
    }
  }
  return out;
}



export default function SearchKurzyPage() {
  const { kurz } = useParams();
  const [kurzyCall, kurzyState] = useSafeAsync<any,any>(LoadKurzy);

  useEffect(()=>{
    kurzyCall(kurz);
  },[])
  
  return(<>
    <LoadingGuard state={kurzyState}>
      {kurzyState.value && <>
        <Stack direction={"row"} spacing={1}>
          <Stack direction={"column"} spacing={1} width={'50%'}>
          <Typography variant="h4" sx={{textAlign:"center"}}>Členové bez kurzu</Typography>
            {Object.keys(kurzyState.value).map((id:any, i:any)=>{
              const one = kurzyState.value[id]
              if (one.kurz != 0) return null;
              return(
              <Box key={id} component={Paper} textAlign={"center"} px={2} py={1}>
                <Typography variant="body1">{`(${one.hodnost}) ${id}`}</Typography>
              </Box>
              )
            })}
          </Stack>

          <Stack direction={"column"} spacing={1} width={'50%'}>
          <Typography variant="h4" sx={{textAlign:"center"}}>Instruktoři</Typography>
            {Object.keys(kurzyState.value).map((id:any, i:any)=>{
              const one = kurzyState.value[id]
              if (one.kurz != 2) return null;
              return(
              <Box key={id} component={Paper} textAlign={"center"} px={2} py={1}>
                <Typography variant="body1">{`(${one.hodnost}) ${id}`}</Typography>
              </Box>
              )
            })}
          </Stack>
        </Stack>
      </>}
    </LoadingGuard>
  </>)
}