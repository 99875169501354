import { getDownloadURL } from "firebase/storage";




export function ReplaceSpecials(id:string) {
  id = id.replace(/ /gi,"-");
  id = id.replace(/\//gi,"");
  id = id.replace(/\\/gi,"");
  id = id.replace(/:/gi,"");
  id = id.replace(/@/gi,"");
  id = id.replace(/&/gi,"");
  id = id.replace(/\[/gi,"");
  id = id.replace(/\]/gi,"");

  id = id.replace(/\+/gi,"");
  id = id.replace(/ě/gi,"e");
  id = id.replace(/š/gi,"s");
  id = id.replace(/č/gi,"c");
  id = id.replace(/ř/gi,"r");
  id = id.replace(/ž/gi,"z");
  id = id.replace(/ý/gi,"y");
  id = id.replace(/á/gi,"a");
  id = id.replace(/í/gi,"i");
  id = id.replace(/é/gi,"e");
  id = id.replace(/ů/gi,"u");
  id = id.replace(/ó/gi,"o");
  id = id.replace(/Ě/gi,"E");
  id = id.replace(/Š/gi,"S");
  id = id.replace(/Č/gi,"C");
  id = id.replace(/Ř/gi,"R");
  id = id.replace(/Ž/gi,"Z");
  id = id.replace(/Ý/gi,"Y");
  id = id.replace(/Á/gi,"A");
  id = id.replace(/Í/gi,"I");
  id = id.replace(/É/gi,"E");
  id = id.replace(/Ů/gi,"U");
  id = id.replace(/Ó/gi,"O");
  id = id.replace(/ú/gi,"u");
  id = id.replace(/Ú/gi,"u");

  return id;
}

export async function GetImageURL(ref:any) {
  const url = await getDownloadURL(ref).then(function(url:any) {
      return url;
  })
  return url;
}

export function CustomObjectSort(obj:object, sortFunc?:any) {
  if(sortFunc){
    return Object.keys(obj)
      .sort(sortFunc)
      .reduce(function (acc:any, key:any) {
        acc[key] = obj[key as keyof typeof obj];
        return acc;
      },  {});
  }

  return Object.keys(obj)
    .sort()
    .reduce(function (acc:any, key:any) {
      acc[key] = obj[key as keyof typeof obj];
      return acc;
    },  {});
  
}

export function getDevice(){
  const userAget = navigator.userAgent;
  if (navigator.userAgent.match(/Android/i)) {
    return "Android"
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return "iOS"
  } else if (navigator.userAgent.match(/Windows/i)) {
    return "Windows"
  } else if (navigator.userAgent.match(/Linux/i)) {
    return "Linux"
  } else {
    return "Other"
  }
}