import { Alert, Button, Stack } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { analytics, auth} from "../components/firebase-public";
import { logEvent } from "firebase/analytics";


export default function LoginPage(props:any){
  const [error,setError] = useState('');
  const Navigate = useNavigate();

  async function FormSent(data:any) {
    logEvent(analytics,'login',{ value: data.login });
    if(error != '') setError('');
    await signInWithEmailAndPassword(auth, data.login, data.pass)
    .then((userCredential) => {
      const user = userCredential.user;
      Navigate("/");
    })
    .catch((error) => {
      console.log(error)
      setError(error.message);
    });
  }

  return (
    <>
    <FormContainer
      defaultValues={{login: '',pass:''}}
      onSuccess={(data:any) => FormSent(data)}
    >
      <Stack direction={'column'} spacing={2} alignItems={'center'} sx={{mt:'10px'}}>
        {error && <Alert severity="error">{error}</Alert>}
        <TextFieldElement name="login" label="Jméno" required/>
        <TextFieldElement name="pass" type={'password'} label="Heslo" required/>
        <Button type="submit" variant='outlined' >Login</Button>
      </Stack>
    </FormContainer>
    </>
  )
}