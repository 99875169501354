import { ChangeEvent, useState } from 'react';
import { Alert, Button, LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';

function FileUpload({path, select}:{path:string, select:boolean}) {
  const [file, setFile] = useState<File | null>(null);
  const [folder, setFolder] = useState<string>("ribbons/");
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadState, setUploadState] = useState<string>("");

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  const handleFolderChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value ) {
      setFolder(e.target.value);
    }
  };

  const handleUpload = () => {
    if (file) {
      const name = file.name.toLowerCase().replaceAll(" ","_");

      const storage = getStorage();
      const imageRef = ref(storage, path+((select)?folder:"")+name);

      const uploadTask = uploadBytesResumable(imageRef, file);
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          setUploadState(snapshot.state);
        }, 
        (error) => {
          // Handle unsuccessful uploads
          setUploadState("error");
          console.error(error);
        }, 
      )
    }
  };

  if (uploadState === "success" || uploadProgress >= 100) return <Alert severity='success'>Nahráno</Alert>
  if (uploadState === "error") return <Alert severity='error'>Chyba!</Alert>
  if (uploadProgress > 0) {
    return <LinearProgress variant="determinate" value={uploadProgress} />
  } else {
    return (
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <input type="file" onChange={handleFileChange} accept="image/png"/>
        {select &&
        <Select name='folder' value={folder} onChange={handleFolderChange} >
          <MenuItem value={'ribbons/'}>Ribbony</MenuItem>
          <MenuItem value={'medals/'}>Medaile</MenuItem>
          <MenuItem value={'badges/'}>Badge</MenuItem>
          <MenuItem value={'citations/'}>Citace</MenuItem>
        </Select>
        }
        <Button variant={"outlined"} onClick={handleUpload}>Nahrát</Button>
      </Stack>
    );
  }
}

export default function FilesUploadPage(props:any) {
  return(
    <Stack direction={"column"} p={2}>
      <Typography variant='body2' mb={2} >Prosím nahrávej jako .png, bez diakritiky a mezer! Například purple_cross.png</Typography>
      <Typography variant='h3' mt={2} >Nahrát ribbon</Typography>
      <FileUpload path={"/system/"} select={true} />

      <Typography variant='h3' my={2}>Nahrát obrázek mise</Typography>
      <FileUpload path={"/zeus/"} select={false}/>
    </Stack>
  )
}