import { Helmet } from 'react-helmet';

export default function Header(props:any) {
  const title = (props.title)?props.title: "44. Ravens Regiment";
  const description = (props.description)?props.description: "Jsme skupina založena pro kooperativní hraní ArmA 3 s inspirací u 75th Ranger Regimentu. Netlačíme realismus před požitek ze hry. Máš zájem? Přidej se k nám!";

  return(
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
    </Helmet>
  )
  
}