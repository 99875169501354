import {firebaseConfig} from "../firebase-config.js";
import { initializeApp } from "firebase/app";
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app,{localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()})});
const analytics = getAnalytics(app);
const storage = getStorage(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6LdV_U4pAAAAADGMI0dS6DPWycPVVZmKWHwJR1dM"),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export {app, db, auth, analytics, storage, appCheck};