import { Alert, Box, CircularProgress } from "@mui/material";
import { AsyncState } from "./useSafeAsync";

export default function LoadingGuard(props:{state:AsyncState<any>,children:any}) {
  
    if (props.state.error) {
      return <Alert severity="error">{props.state.error}</Alert>
    }
    
    if (props.state.isLoading || props.state.isCalled == false) {
      return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'}><CircularProgress /></Box>
    }else {
      return props.children
    }
  }