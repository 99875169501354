import { AppBar, Button, IconButton, Link, Menu, MenuItem, Stack, Toolbar, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./components/context/firebaseContext";
import { User as FirebaseUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Input } from "@mui/icons-material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./components/firebase-public";
import { UserContext } from "./components/context/userContext";
import Header from "./components/header";


async function CheckAdmin(id:string|undefined,set:Function) {
  if (typeof id === "undefined") return false;

  const adminColl = doc(db,'admins',id)
  const adminSnap = await getDoc(adminColl);

  return set(adminSnap.exists());
}

async function CheckUserName(uid:string|undefined,set:Function) {
  if (typeof uid === "undefined") return null;

  const userColl = doc(db,'users',uid);
  const userSnap = await getDoc(userColl);

  if (userSnap.exists()) {
    set(userSnap.data().clen)
    return userSnap.data().clen; //ziskani id prihlaseneho clena
  }
  return null;
}


export default function Layout(props:any) {
  const [user,setUser] = useState<FirebaseUser|null>(null)
  const [userCalled, setUserCalled] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [userName, setUserName] = useState<string|null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [joke, setJoke] = useState(0);;
  const {auth} = useContext(FirebaseContext);
  const Navigate = useNavigate();

  const isDesktop = useMediaQuery('(min-width:1000px)');
  
  //useEffect(() => {document.title = ((props.title)?`${props.title} - 44. Ravens Regiment`:"44. Ravens Regiment")}, []) //zmena titulku stranky

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(_user => {
      if(_user?.isAnonymous){setUserCalled(true); setUser(_user); return null;}
      CheckAdmin(_user?.uid,setIsAdmin).then(()=>{
        CheckUserName(_user?.uid,setUserName).then(()=>{
          setUserCalled(true);
          setUser(_user);
        })
      })
    });
    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
    if(joke >= 44){window.location.replace("https://bohnice.cz/krizova-pomoc/")}
  },[joke])


  let toolbar = <></>;
  function handleUser() {
    if (user && user.isAnonymous) {
      Navigate("/login");
    }else if(!user){
      Navigate("/login");
    }
  }

  if (isDesktop) {
    toolbar = <Toolbar>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'} width={'100%'} maxHeight={'40px'}>
        <img src="/logo512.png" height={150} alt="logo" style={{position:"relative",top: "-50px", marginRight: "auto"}} onClick={()=>{setJoke(joke + 1);}}/>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Link href="/" style={{ textDecoration: 'none' }}><Button variant="outlined">Domů</Button></Link>
          <Link href="/o-nas" style={{ textDecoration: 'none' }}><Button variant="outlined">O nás</Button></Link>
          <Link href="/clenove" style={{ textDecoration: 'none' }}><Button variant="outlined">Členové</Button></Link>
          <Link href="/pridat" style={{ textDecoration: 'none' }}><Button variant="outlined">Přidej se</Button></Link>
          {(user && !user.isAnonymous)?<Link href="/pro-cleny" style={{ textDecoration: 'none' }}><Button variant="outlined">Pro členy</Button></Link>:<></>}
          {(user && isAdmin)?<Link href="/admin" style={{ textDecoration: 'none' }}><Button variant="outlined">Admin</Button></Link>:<></>}
          {(user == undefined || (user && user.isAnonymous))?<IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleUser}
          color="inherit"
          >
            <Input color="primary" />
          </IconButton>:<></>}
        </Stack>
      </Stack>
    </Toolbar>
  } else {
    toolbar = <Toolbar>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'} width={'100%'} maxHeight={'50px'}>
        <IconButton size="large"
          edge="start"
          color="primary"
          sx={{ mr: 2}}
          onClick={(e) => {setMenuAnchor(e.currentTarget);}}
          >
            <MenuIcon />
        </IconButton>
        <Menu
          onClose={()=>{setMenuAnchor(null)}}
          open={Boolean(menuAnchor)}
          keepMounted
          anchorEl={menuAnchor}
        >
          <MenuItem><Link href="/" style={{ textDecoration: 'none' }}>Domů</Link></MenuItem>
          <MenuItem><Link href="/o-nas" style={{ textDecoration: 'none' }}>O nás</Link></MenuItem>
          <MenuItem><Link href="/clenove" style={{ textDecoration: 'none' }}>Členové</Link></MenuItem>
          <MenuItem><Link href="/pridat" style={{ textDecoration: 'none' }}>Přidej se</Link></MenuItem>
          {(user && !user.isAnonymous)?<MenuItem><Link href="/pro-cleny" style={{ textDecoration: 'none' }}>Pro členy</Link></MenuItem>:<div></div>}
          {(user && isAdmin)?<MenuItem><Link href="/admin" style={{ textDecoration: 'none' }}>Admin</Link></MenuItem>:<div></div>}
          {(user == undefined || (user && user.isAnonymous))?<MenuItem><IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleUser}
          color="inherit"
          >
            <Input color="primary" />
          </IconButton></MenuItem>:<div></div>}
        </Menu>
        
        <img src="/logo192.png" height={100} alt="logo" style={{position:"relative",top: "-25px",marginLeft:"auto"}} onClick={()=>{Navigate("/")}}/>
      </Stack>
    </Toolbar>
  }
  




  return(
  <>
  <UserContext.Provider value={{user,setUser, userCalled, isAdmin, userName}}>
    <Header title={(props.title)?`${props.title} - 44. Ravens Regiment`:null} description={props.description}/>
    <AppBar position='static'  sx={{my:(isDesktop)?5:3}}>
      {toolbar}
    </AppBar>
    {props.children}
  </UserContext.Provider>
  </>
  )
}
