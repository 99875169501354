import { Link, Stack} from "@mui/material"

export default function Redirect({url}:{url:string}) {
  return(<>
  <meta http-equiv="Refresh" content={`0; url='${url}'`} />
  <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} height={"100vh"} spacing={2}>
    <Link href={url}>Pro přesměrování klikněte zde: {url}</Link>
  </Stack>
  </>)
  
}