import { Box, Grid, Paper, Stack, Typography, Link, CircularProgress, useMediaQuery } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import {ReactComponent as TwitchIcon} from "../icons/twitch.svg";
import {ReactComponent as YoutubeIcon} from "../icons/youtube.svg";
import {ReactComponent as SteamIcon} from "../icons/steam.svg";
import {ReactComponent as InstagramIcon} from "../icons/instagram.svg";
import { ref } from "firebase/storage";
import { useState, useEffect } from "react";
import { storage } from "./firebase-public";
import { GetImageURL } from "./helpers";



export default function MemberInfo({member}:{member:any}) {
    const date = new Timestamp(member.clen_od.seconds,member.clen_od.nanoseconds)
    const isNotPhone = useMediaQuery('(min-width:600px)');
    const IsBigMonitor = useMediaQuery('(min-width:1921px)');

    return(<>
    <Stack direction={"column"} my={5} mx={(isNotPhone)?5:5} alignItems={"center"} >
      <Grid container component={Paper} p={2} spacing={2}>
        <Grid item xs={12} justifyContent={"center"} display={"flex"}>
            <Typography variant="h3">{member.hodnost} {member.id}</Typography>
        </Grid>
        
        <Grid item xs={9}>
          <Typography variant="body1">Členem od: {date.toDate().toLocaleDateString("cs-CZ")}</Typography>
        </Grid>
        <Grid item xs={3}>
          {(member.social.yt)?
          <Link href={member.social.yt} target={"_blank"} style={{textDecoration:"none"}}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box height={"30px"}><YoutubeIcon fill="#f00" stroke="#f00" height={"100%"}/></Box>
            <Typography variant="body1" color={"#f00"}>Youtube</Typography>
          </Stack>
          </Link>
          :<></>}
        </Grid>
        
        <Grid item xs={9}>
            
        </Grid>
        <Grid item xs={3}>
          {(member.social.twitch)?
          <Link href={member.social.twitch} target={"_blank"} style={{textDecoration:"none"}}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box height={"30px"}><TwitchIcon fill="#a970ff" stroke="#a970ff" height={"100%"}/></Box>
            <Typography variant="body1" color={"#a970ff"}>Twitch</Typography>
          </Stack>
          </Link>
          :<></>}
        </Grid>
        
        <Grid item xs={9}>
            
        </Grid>
        <Grid item xs={3}>
          {(member.social.insta)?
          <Link href={member.social.insta} target={"_blank"} style={{textDecoration:"none"}}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box height={"30px"}><InstagramIcon height={"100%"}/></Box>
            <Typography variant="body1" color={"white"}>Instagram</Typography>
          </Stack>
          </Link>
          :<></>}
        </Grid>
       
        <Grid item xs={9}>
            
        </Grid>
        <Grid item xs={3}>
          {(member.social.steam)?
          <Link href={member.social.steam} target={"_blank"} style={{textDecoration:"none"}}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box height={"30px"}><SteamIcon height={"100%"}/></Box>
            <Typography variant="body1" color={"white"}>Steam</Typography>
          </Stack>
          </Link>
          :<></>}
            
        </Grid>

      </Grid>
      
      <RibbonsGrid ribbons={member.medals} path={"medals"} label={"Medaile"}/>
      <RibbonsGrid ribbons={member.ribbons} path={"ribbons"} label={"Ribbony"} />
      <RibbonsGrid ribbons={member.badges} path={"badges"} label={"Badge"}  />
      <RibbonsGrid ribbons={member.citations} path={"citations"} label={"Citace"}  />
    </Stack>
    </>);
}

function RibbonsGrid(props:any) {
  const IsBigMonitor = useMediaQuery('(min-width:1921px)');
  if (!props.ribbons) return <></>

  return(<>
    <Typography variant="h4" mt={2}>{props.label}</Typography>
    <Grid container spacing={2} mt={1} >
      {Object.keys(props.ribbons).sort().map((key,index)=>{
        return (<Grid item key={key} xs={6} md={3}display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <RibbonImage image={props.ribbons[key]} path={props.path} />
          </Grid>);
      })}
    </Grid>
  </>);
}

function RibbonImage(props:any) {
  const isNotPhone = useMediaQuery('(min-width:600px)');
  const [imgUrl,setImgUrl] = useState('');
  useEffect(()=>{
    GetImageURL(ref(storage,'system/'+props.path+'/'+props.image+'.png')).then(url => setImgUrl(url))
  },[])

  if(imgUrl === ''){
    return(<Box display={'flex'} alignContent={'center'} alignItems={'center'}><CircularProgress /></Box>)
  }else{
    return(<Stack direction={"column"} spacing={1} alignItems={'center'} justifyContent={'center'}>
      <img src={imgUrl} alt={props.image} title={props.image} style={(isNotPhone)?{maxWidth:'200px', maxHeight:'100px'}:{maxWidth:'130px', maxHeight:'65px'}}/>
      <Typography variant="body2" sx={{textTransform: "capitalize"}}>{props.image.replaceAll("_"," ")}</Typography>
      </Stack>)
  }
}