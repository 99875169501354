import { signInAnonymously } from "firebase/auth";
import { analytics, auth, db, storage } from "../components/firebase-public";
import { Timestamp, collection, getDocs } from "firebase/firestore";
import { Box, Grid, Link, Stack, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import LoadingGuard from "../components/loadingGuard";
import { useSafeAsync } from "../components/useSafeAsync";
import { UserContext } from "../components/context/userContext";
import { ref } from "firebase/storage";
import { GetImageURL } from "../components/helpers";
import { logEvent } from "firebase/analytics";
/*
 try {
    //zkouska offline
      
  } catch {
    //offline neni tak zkouska online
    
  } finally {
    //a tady je zpracovani

  }
*/

async function LoadMembersList() {
  var membersData: any[] = [];
  const membersColl = collection(db,'clenove')
  const membersSnap = await getDocs(membersColl);
  if (!membersSnap.empty) {
    // The collection has documents
    membersSnap.forEach((doc) => {
      membersData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  } 
  
  return  membersData;
}

export default function MembersPage(props:any) {
  const [error, setError] = useState('');
  const [membersCall, membersState] = useSafeAsync<any,any>(LoadMembersList);
  const userObject = useContext(UserContext);
  const [backImg, setBackImg] = useState("");
  const isNotPhone = useMediaQuery('(min-width:600px)');
  const IsBigMonitor = useMediaQuery('(min-width:1921px)');

  useEffect(()=>{
    if (userObject && userObject.user) { membersCall({}); }
    else if (userObject && userObject?.userCalled && !userObject.user){signInAnonymously(auth).then(() => {membersCall({});}).catch((e)=>{setError(e.message)})}
  
    if(backImg === ''){
      GetImageURL(ref(storage,'system/clenove.png')).then(url => setBackImg(url))
    }
  },[userObject])


  return(<>
    <LoadingGuard state={membersState} >
        {membersState.value &&
        <>
        <Stack direction={"column"} p={4} minHeight={"100vh"} sx={{background:'url('+backImg+')', backgroundRepeat:'no-repeat', backgroundAttachment:'fixed', backgroundSize:"cover"}}>
          <Typography variant="h3" textAlign={"center"}>Naši členové</Typography>
          <MembersGrid value={membersState.value} />
        </Stack>
        </>
        }
    </LoadingGuard>
  </>)
}


function MembersGrid(props:any) {

  function SortByRank(a:any,b:any) {
    if(a.hodnost.match(/RTR/i)) return 1;
    return b.hodnost_order - a.hodnost_order;
  }

  const enlisted = props.value.map((el:any)=>{ if (el.hodnost_order <= 12)return el}).filter((item:any) => item !==undefined).sort(SortByRank)
  const warrants = props.value.map((el:any)=>{ if (el.hodnost_order >= 13 && el.hodnost_order <= 17) return el }).filter((item:any) => item !==undefined).sort(SortByRank)
  const officers = props.value.map((el:any)=>{ if (el.hodnost_order >= 18) return el }).filter((item:any) => item !==undefined).sort(SortByRank)


  

  return(<>
  
    <Grid container spacing={2} direction={"row"} >
      <Grid item xs={12} mb={1}><Typography variant="h5"  textAlign={"center"}>Officers:</Typography></Grid>
      {officers.map((el:any) => {
        return (<MembersCard key={el.id} member={el} />);
      })}
    </Grid>
    
    <Grid container spacing={2} direction={"row"} mt={4}>
      <Grid item xs={12} mb={1}><Typography variant="h5"  textAlign={"center"}>Warrant Officers:</Typography></Grid>
      {warrants.map((el:any) => {
        return (<MembersCard key={el.id} member={el} />);
      })}
    </Grid>
    
    <Grid container spacing={2} direction={"row"} mt={4} >
      <Grid item xs={12} mb={1}><Typography variant="h5" textAlign={"center"}>Enlisted:</Typography></Grid>
      {enlisted.map((el:any) => {
        return (<MembersCard key={el.id} member={el} />);
      })}
    </Grid>

  </>)
}

function MembersCard(props:any) {
  const IsBigMonitor = useMediaQuery('(min-width:1921px)');

  const member = props.member;
  const date = new Timestamp(member.clen_od.seconds,member.clen_od.nanoseconds)
  return(<>
    <Grid item xs={6} md={(IsBigMonitor)?2:3} key={member.id} textAlign={"center"} alignItems={"center"} justifyItems={"center"}>
      {(member.hodnost_order > 0)?<img src={`/img/hodnosti/h${member.hodnost_order}.png`} alt={member.hodnost} height={"40px"}/>:<Box height={"40px"} />}
      <Link href={`/clen/${member.id}`} style={{ textDecoration: 'none' }}
       onClick={()=>{logEvent(analytics,'user_link',{ value: member.id })}}
      >
        <Typography variant="h4">{member.id}</Typography>
      </Link>
      <Typography variant="body1">{member.hodnost}</Typography>
      <Typography variant="body2">{date.toDate().toLocaleDateString("cs-CZ")}</Typography>
    </Grid>
  </>)
}