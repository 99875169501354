import { Box, Button, CircularProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import SocialBox from "../components/social-box";
import { useNavigate } from "react-router-dom";
import InfoBox from "../components/infobox";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../components/context/firebaseContext";
import { GetImageURL } from "../components/helpers";
import { ref } from "firebase/storage";


export default function HomePage(props:any) {
const theme = useTheme();
const Navigate = useNavigate()
const [backImg,setBackImg] = useState('');
const isNotPhone = useMediaQuery('(min-width:600px)');
const IsBigMonitor = useMediaQuery('(min-width:1921px)');
const {storage} = useContext(FirebaseContext);


useEffect(()=>{
  GetImageURL(ref(storage,'system/bckg.png')).then(url => setBackImg(url))
},[])


if (backImg === '') {
  return(<><Box height={"50vh"}
  display={"flex"} alignItems={"center"} justifyContent={"center"}
  >
    <CircularProgress />
  </Box></>)
}


return(
    <>
      <Stack direction={"column"} pt={2}>
        <Box height={(isNotPhone)?"100vh":"50vh"}>
        <Box component={"img"} 
          src={backImg}
          height={(isNotPhone)?"100vh":"50vh"}
          width={"100%"}
          position={"absolute"}
          zIndex={-1}
          sx={{objectFit:(IsBigMonitor)?"":((isNotPhone)?"none":"cover")}}
        />
          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
            <Box component={"img"} src="/logoRanger.png" width={(isNotPhone)?"250px":"40vw"} mb={1}></Box>
              <Box width={"100%"} sx={{
                alignSelf:"center"
              }}>
              <Stack direction={"column"} alignSelf={"center"} alignItems={"center"} justifyContent={"center"} sx={{background:"#ffffff45"}} py={2}>
                <Typography variant="h1" color={theme.palette.primary.contrastText} fontWeight={600}>44. RAVENS REGIMENT</Typography>
                <Typography variant="h2" color={theme.palette.primary.contrastText} mb={1}>CZ/SK Arma 3 Skupina</Typography>
                <Button variant="contained" color="black" onClick={()=>{Navigate("/pridat")}} >Přidej se k nám</Button>
              </Stack>
            </Box>
          </Stack>
        </Box>

        <Stack direction={(isNotPhone)?"row":"column"} alignItems={(isNotPhone)?"flex-start":"center"} spacing={1} justifyContent={"space-evenly"} my={5} px={10} textAlign={"center"}>
          <InfoBox  text={"Více než 9 let zkušeností"} icon={"award"} />
          <InfoBox  text={"Aktivní kolektiv"} icon={"user"} />
          <InfoBox  text={"Vhodné pro začátečníky i pokročilé hráče"} icon={"thumbs"} />
          <InfoBox  text={"Vlastní addon pack"} icon={"layer"} />
        </Stack>

        <Box minHeight={(isNotPhone)?"500px":"50vh"} sx={{
          backgroundImage:"url(/img/parralax.png)",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }} />

        <Box my={5} px={(isNotPhone)?10:0.5} textAlign={"center"}>
          <Typography variant="body2" mb={3}>44. Ravens Regiment (44. RR) je skupina založena primárně pro kooperativní hraní ArmA 3 (Arma 3 CZ/SK) s inspirací u 75th Ranger Regimentu. Aby jsme se naší reálné předloze co nejvíce přiblížili tak máme soukromé módy které nám přidávají kvalitní gear, Rangery inspirovaný ORBAT a z reality přebrané postupy.</Typography>
          <Typography variant="body2">Jsme aktivní Český klan, kde se několik jedinců věnuje simulačním vojenským hrám již od roku 2013, snažící se nejen neustále zlepšovat na poli hry ArmA 3, ale především s cílem vybudovat fungující jednotnou a přátelskou komunitu, kterou spojuje nejen hra ArmA 3. V současné době je tato komunita v podstatě samostatnou skupinou založenou nejen na hraní jedné hry, ale i na osobních vztazích a zkušenostech.</Typography>
        </Box>
        
        <Stack direction="row" alignItems={"center"} justifyContent={"space-evenly"} my={(isNotPhone)?5:0.5} px={(isNotPhone)?10:0.5} spacing={(isNotPhone)?0:1} zIndex={1}>
          <SocialBox href="/discord" icon="discord" text="Discord" />
          <SocialBox href="https://www.instagram.com/ravensregiment44/" icon="instagram" text="Instagram" />
          <SocialBox href="https://steamcommunity.com/groups/44RavensRegiment" icon="steam" text="Steam" />
          <SocialBox href="https://www.youtube.com/@44.ravensregiment36" icon="youtube" text="YouTube" />
        </Stack>
      </Stack>
    </>
    )
}