import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSafeAsync } from "../components/useSafeAsync";
import { db } from "../components/firebase-public";
import LoadingGuard from "../components/loadingGuard";
import { Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import MemberInfo from "../components/memberInfo";


async function LoadMember({id}:{id:string}) {
  const membersColl = doc(db,'clenove',id)
  const membersSnap = await getDoc(membersColl);
  if (membersSnap.exists()) {
    var obj = membersSnap.data();
    obj.id = membersSnap.id;
    return obj;
  }
  return null;
}


export default function MemberPage(props:any) {
  const [error, setError] = useState('');
  const { member } = useParams();
  const [membersCall, memberState] = useSafeAsync<any,any>(LoadMember);

  useEffect(()=>{
    membersCall({id:member});
  },[])

  
  return(<>
    {(error !== '') && <Typography>{error}</Typography>}
    <LoadingGuard state={memberState} >
        {memberState.value && 
        <>
        <MemberInfo member={memberState.value} />
        </>
        }
    </LoadingGuard>
  </>)
}