import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import {ReactComponent as Award} from "../icons/award-solid.svg";
import {ReactComponent as Group} from "../icons/user-group-solid.svg";
import {ReactComponent as Thumbs} from "../icons/thumbs-up-solid.svg";
import {ReactComponent as Layer} from "../icons/layer-group-solid.svg";




export default function InfoBox(props:any) {
  const isNotPhone = useMediaQuery('(min-width:600px)');
  const IsBigMonitor = useMediaQuery('(min-width:1921px)');

  var icon = <Award fill="white" stroke="white" height={"100%"}/>;
  switch (props.icon) {
    case "award":
      icon = <Award fill="white" stroke="white" height={"100%"}/>;
      break;
    case "thumbs":
      icon = <Thumbs fill="white" stroke="white" height={"100%"} />;
      break;
    case "user":
      icon = <Group fill="white" stroke="white" height={"100%"} />;
      break;
    case "layer":
      icon = <Layer fill="white" stroke="white" height={"100%"} />;
      break;

  }

  return(<>
    <Stack direction={"column"} justifyContent={"flex-start"} alignItems={"center"} width={(isNotPhone)?"25%":"100%"}>
      <Box height={(isNotPhone)?"100px":"50px"}>{icon}</Box>
      <Typography variant={(isNotPhone)?"body1":"body2"} mt={1}>{props.text}</Typography>
    </Stack>
  </>);
  
}