import { Button, Link, Stack, Typography } from "@mui/material"


export default function ErrorPage(props:any) {
    return(
        <>
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} height={"100vh"} spacing={2}>
          <Typography variant="h2">404 - Something went wrong</Typography>
          <Link href="/"><Button variant="outlined">Back to start</Button></Link>
        </Stack>
        </>
        )
    }