import { Box, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import {ReactComponent as Steam} from "../icons/steam.svg";
import {ReactComponent as Youtube} from "../icons/youtube.svg";
import {ReactComponent as Instagram} from "../icons/instagram.svg";
import {ReactComponent as Discord} from "../icons/discord.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase-public";

export default function SocialBox(props:any) {
    const theme = useTheme();
    const isNotPhone = useMediaQuery('(min-width:600px)');
    const IsBigMonitor = useMediaQuery('(min-width:1921px)');

    var icon = <Youtube fill="white" stroke="white" height={"100%"}/>;
  switch (props.icon) {
    case "youtube":
      icon = <Youtube fill="#f00" stroke="#f00" height={"100%"}/>;
      break;
    case "steam":
      icon = <Steam height={"100%"} />;
      break;
    case "instagram":
      icon = <Instagram height={"100%"} />;
      break;
    case "discord":
      icon = <Discord fill="#5865f2" stroke="#5865f2" height={"100%"} />;
      break;

  }

return(<>
    <Link onClick={()=>{logEvent(analytics,'social_link_main',{ value: props.text })}} href={props.href} target={"_blank"} style={{textDecoration:"none"}}>
    <Stack direction={"column"} alignItems={"center"}>
        <Box height={(isNotPhone)?"100px":"50px"}>{icon}</Box>
        <Typography variant={(isNotPhone)?"body1":"body2"} color={theme.palette.primary.main}>{props.text}</Typography>
    </Stack></Link>
    </>);
}