import { Typography, Stack, Box, Button, IconButton, Switch, TextField, Link, Autocomplete } from "@mui/material";
import { cs } from "date-fns/locale";
import { useEffect, useState } from "react";
import { FormContainer, TextFieldElement, DateTimePickerElement, SelectElement, TextareaAutosizeElement, useFormContext, useFieldArray, CheckboxElement, Controller } from "react-hook-form-mui";
import DateFnsProvider from "../DateFnsProvider";
import { Add, Close } from "@mui/icons-material";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-private";
import { ReplaceSpecials } from "../helpers";
import { defaultRangersSlots } from "./defaultSlots";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, listAll, ref } from "firebase/storage";
import { AsyncAutocomplete, AutocompleteOptions } from "../asyncAutocomplete";
import { GearFormElement } from "../gearFormElement";
import LoaderButton from "../loaderButton";

const functions = getFunctions();
const sendNotification = httpsCallable(functions, 'notifications-send');

type Slot = {
  platoon:string,
  slot:string,
  role:string
}


function SaveForm(data:any) {
  const finalData:any = {}
  const newSloty:any = {};

  finalData.sloty = {}
  if (data.datum !== ""){
    finalData.datum = Timestamp.fromDate(data.datum)
  }else{
    finalData.datum = Timestamp.fromDate(new Date("01-01-1970"))
  }
  if (data.datum_ingame !== ""){
    finalData.datum_ingame = Timestamp.fromDate(data.datum_ingame)
  }else{
    finalData.datum_ingame = Timestamp.fromDate(new Date("01-01-1970"))
  }

  Object.keys(data).map((i)=>{
    if(data[i] === undefined) data[i] = "";
    if (typeof data[i] === "object") {
      Object.keys(data[i]).map((j)=>{
        if(data[i][j] === undefined) data[i][j] = "";
      })
    }
  })

  Object.keys(data.sloty).map((i:any)=>{
    const platoon = data.sloty[i];
    if (!platoon.name) {
      newSloty[i] = data.sloty[i]; // pro ofiko ORBAT
    } else {
      if(platoon.name !== ""){
        newSloty[platoon.name] = {};
        //if(!platoon.value) platoon.value={platoon};
        Object.keys(platoon.value).map((j)=>{
          const slot = platoon.value[j];
          if (typeof slot.kit === "undefined") slot.kit = "";

          if(slot.slot !== "") newSloty[platoon.name][slot.slot] = {kit: slot.kit, clen: "", povoleno: true}
        })
      }
    }
  })

  finalData.nazev = data.nazev;
  finalData.typ = data.typ;
  finalData.povinne = data.povinne;
  finalData.povolene = data.povolene;
  finalData.zakazane = data.zakazane;
  finalData.dodatecne = data.dodatecne;
  if (data.gear) {
    if (typeof data.gear === "string") {
      finalData.gear = data.gear;
    } else {
      finalData.gear = data.gear.url;
    }
  } else {
    finalData.gear = "";
  }

  
  if (typeof data.image === "string") {
    finalData.image = data.image;
  } else {
    finalData.image = data.image.value;
  }
  
  finalData.mapa = data.mapa;
  finalData.valecne_pravo = data.valecne_pravo;
  finalData.briefing = data.briefing;

  finalData.sloty = newSloty;

  finalData.dokoncena = "ne";

  const memberRef = doc(db, 'mise', data.id);
  return setDoc(memberRef, finalData, { merge: true }).then(() => {
    const typ = ((data.typ === "o")?"Oficiální":((data.typ === "n")?"Neoficiální":"Tréninková"))
    const datum = `v ${data.datum.toLocaleString("cs-CZ",{weekday:"long"})} ${data.datum.toLocaleDateString("cs-CZ")} v ${data.datum.toLocaleTimeString("cs-CZ").slice(0,-3)}`;
    return sendNotification({title:"Byla vyhlášena nová mise", text: `Nová (${typ}) mise ${data.nazev} ${datum}`, url: `https://ravens-regiment.com/mise/${data.id}`});
  });
}

export default function MissionNew(props:any) {
  const [step,setStep] = [props.step, props.setStep];
  const [missionId,setMissionId] = useState('');

  if (missionId !== '') {
    return(<Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
      <Typography variant="h3" textAlign={"center"}>Mise vložena</Typography>
      <Link href={"/mise/"+missionId} textAlign={"center"}>/mise/{missionId}</Link>
    </Stack>)
  }


  return(<>
      <FormContainer
        defaultValues={{datum: new Date(), datum_ingame: new Date(0), typ:"o",valecne_pravo:"ano",sloty:defaultRangersSlots,id:'',}}
        onSuccess={(data)=>{SaveForm(data).then(()=>{setMissionId(data.id)})}}
      >
        <Stack direction={"column"} spacing={2} p={3}>
        <DynamicMissionName />
        <SelectElement name="typ" label="Typ" options={[{id:"o",label:"Oficiální - bez deploymentu"},{id:"n",label:"Neoficiální"},{id:"t",label:"Trénink"}]} />
        <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum konání" name="datum" /></DateFnsProvider>
        <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum (ingame)" name="datum_ingame" /></DateFnsProvider>
        <TextFieldElement label="Mapa" name="mapa" />
        <ImagesFormElement/>
        
        <GearFormElement />

        <WeaponsFormElements />
        
        <Typography variant="h4" pt={4}>Dodatečné informace: </Typography>
        <TextFieldElement label="Povinné" name="povinne" />
        <TextFieldElement label="Povolené" name="povolene" />
        <TextFieldElement label="Zakázané" name="zakazane" />
        <TextareaAutosizeElement label="Dodatečné" name="dodatecne" />
        <SelectElement name="valecne_pravo" label="Valečné právo" options={[{id:"ano",label:"ano"},{id:"ne",label:"ne"},]} />
        <TextFieldElement label="Odkaz na briefing" name="briefing" />

        <PlatoonsElements />

        <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Vložit!</LoaderButton></Box>
        </Stack>
      </FormContainer>
    </>)
}

function DynamicMissionName() {
  const form = useFormContext();
  
  return(<>
    <TextFieldElement label="Název mise" name="nazev" onChange={(t)=>{
        var target = t.target as HTMLInputElement;
        form.setValue("id",ReplaceSpecials(target.value));
      }}
    />
    <TextFieldElement label="ID mise" name="id" InputProps={{readOnly: true,}}/>
  </>)
}

function ImagesFormElement(props:any) {
  const { control } = useFormContext();

  async function LoadImages() {
    const storage = getStorage();
    const zeusRef = ref(storage, '/zeus/');
    var names:Array<AutocompleteOptions> = [];

    await listAll(zeusRef).then((res) => {      
      res.items.forEach((itemRef) => {
        names.push({label:itemRef.name, value:itemRef.name})
      });      
    })
    return names
  }

  return (<AsyncAutocomplete control={control} inputName="image" inputLabel="Obrázek mise" asyncFunc={LoadImages}/>)
}


function WeaponsFormElements() {
  const [checked,setChecked] = useState(false);

  return(<>
    <Stack direction={"row"} alignItems={"flex-end"}>
      <Typography variant="h4" pt={4}>Vlastní zbraně: </Typography>
      <Switch onChange={()=>setChecked(!checked)} color={(checked)?"success":"primary"} />
    </Stack>

    {(checked)
      ?<>
        <TextFieldElement label="Pušky" name="zbrane[pusky]" />
        <TextFieldElement label="Ostrostřelecké pušky" name="zbrane[ostro]" />
        <TextFieldElement label="Odstřelovací pušky" name="zbrane[odstrel]" />
        <TextFieldElement label="Lehké kulomety" name="zbrane[lkulomety]" />
        <TextFieldElement label="Střední kulomety" name="zbrane[skulomety]" />
        <TextFieldElement label="Raketomety" name="zbrane[raketomety]" />
        <TextFieldElement label="Granátomety" name="zbrane[granatomety]" />
      </>
      :<></>}
  </>)
}

function PlatoonsElements() {
  const form = useFormContext();
  const control = form.control;
  const register = form.register;
  const [checked,setChecked] = useState(false);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "sloty", // unique name for your Field Array
  });

  function addPlatoon() {
    append({name:`platoon${fields.length}`,value:{}})
  }

  function addZeus() {
    append({name:`ZEUS`,value:{}})
  }

  function addBackup() {
    append({name:`NAHRADNICI`,value:{}})
  }

  return(<>

    <Stack direction={"row"} alignItems={"flex-end"}>
      <Typography variant="h4" pt={4}>Vlastní Sloty: </Typography>
      <Switch onChange={()=>setChecked(!checked)} color={(checked)?"success":"primary"} />
    </Stack>
    {(checked)
      ?<>
      <Stack direction={"column"} spacing={1}>
        {fields.map((field:any, index) => (
          <SlotsElements
          key={field.id} // important to include key with field's id
          {...register(`sloty.${index}`)}
          name={field.name}
          index={index}
          platoon={field.name}
        />
        ))}
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Box><Button variant="outlined"  onClick={addPlatoon}><Add /> Platoon</Button></Box>
        <Box><Button variant="outlined"  onClick={addZeus}><Add /> Default Zeus</Button></Box>
        <Box><Button variant="outlined"  onClick={addBackup}><Add /> Default Backup</Button></Box>
      </Stack>
      </>
      :<>
      {Object.keys(defaultRangersSlots).map((plat:any)=>{
        const platoon = defaultRangersSlots[plat as keyof typeof defaultRangersSlots];
        return(<Stack key={plat} direction={"column"}ml={3}>
          <Typography variant="h5">{plat}</Typography>
          {Object.keys(platoon).map((slot:any)=>{
            const one:any = platoon[slot as keyof typeof platoon];
            return(<Stack key={plat+"-"+slot} direction={'row'} spacing={1} alignItems={"center"}>
              <TextField disabled={true} value={slot}>{slot}</TextField>
              <TextFieldElement name={`sloty[${plat}][${slot}].kit`} label={"Kit"}>{one.kit}</TextFieldElement>
              <TextFieldElement name={`sloty[${plat}][${slot}].clen`} label={"Clen"}>{one.clen}</TextFieldElement>
              <CheckboxElement name={`sloty[${plat}][${slot}].povoleno`} checked={one.povoleno} />
            </Stack>)
          })}
        </Stack>)
      })
      }
      </>
    }

    
  </>)
}

function SlotsElements(props:any) {
  const form = useFormContext();
  const control = form.control;
  const register = form.register;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "platoon-"+props.platoon, // unique name for your Field Array
  });

  function addSlot() {
    append({slot: "",kit: "",clen: "", povoleno:true})
    
  }
  useEffect(()=>{
    form.setValue(`sloty.${props.index}.value`,{...fields})
  },[append,fields]) 
  

  if (props.platoon === "ZEUS" && fields.length == 0) {
    append({slot: "ZEUS-1",kit: "Zeus",clen: "", povoleno:true})
    append({slot: "ZEUS-2",kit: "Zeus",clen: "", povoleno:true})
    append({slot: "ZEUS-3",kit: "Zeus",clen: "", povoleno:true})
    append({slot: "ZEUS-4",kit: "Zeus",clen: "", povoleno:true})
  }
  if (props.platoon === "NAHRADNICI" && fields.length == 0) {
    append({slot: "N-1",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-2",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-3",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-4",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-5",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-6",kit: "Náhradník",clen: "", povoleno:true})
    append({slot: "N-7",kit: "Náhradník",clen: "", povoleno:true})
  }
  
  


  return(<>

    <TextFieldElement name={`sloty.${props.index}.name`} label={"Název"}>{props.name}</TextFieldElement>
    <Stack direction={"column"} spacing={1} pl={3}>
      {fields.map((field:any, index) => {
        form.setValue(`sloty.${props.index}.value`,{...fields}) // Pro nacteni vychozi hodnoty
        return(<Stack key={field.id} direction={'row'} spacing={1}>
          <TextFieldElement name={`sloty.${props.index}.value[${index}].slot`} label={"Slot"} />
          <TextFieldElement name={`sloty.${props.index}.value[${index}].kit`} label={"Kit"} />
          <TextFieldElement name={`sloty.${props.index}.value[${index}].clen`} label={"Clen"} />
          <Box><IconButton onClick={()=>{remove(index)}}><Close /></IconButton></Box>
        </Stack>)
      })}
    </Stack>
    <Box><Button variant="outlined"  onClick={addSlot}><Add /> Slot</Button></Box>
  </>)
}