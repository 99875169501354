import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import { Timestamp, doc, getDoc, setDoc } from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import { auth, db } from "../firebase-private";
import LoadingGuard from "../loadingGuard";
import { useSafeAsync } from "../useSafeAsync";
import { DateTimePickerElement, FormContainer, SelectElement, TextFieldElement, useFormContext} from "react-hook-form-mui";
import Member from "../types/memberType";
import DateFnsProvider from "../DateFnsProvider";
import cs from "date-fns/locale/cs";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserContext } from "../context/userContext";
import { sendPasswordResetEmail } from "firebase/auth";
import LoaderButton from "../loaderButton";

const functions = getFunctions();
const createUser = httpsCallable(functions, 'createUser');

async function LoadKurzy() {
  const kurzyColl = doc(db,'kurzy','all')
  const kurzySnap = await getDoc(kurzyColl);

  //nacteni kurzu z DB
  if (kurzySnap.exists()) {
    var kurzy = kurzySnap.data().all;
    kurzy.sort((a:string, b:string) => a > b ? 1 : -1,)
    return kurzy
  }
  return null;
}
async function LoadRanks() {
  const rankColl = doc(db,'hodnosti','all')
  const rankSnap = await getDoc(rankColl);

  if (rankSnap.exists()) {
    const data = rankSnap.data();
    if (data) {
      return Object.values(data).sort((a, b) => a.order - b.order);
    }
    return []
  }else{
    return [];
  }
}

function SaveMember(id:string,data:Member) {
  const memberRef = doc(db, 'clenove', id);
  setDoc(memberRef, data, { merge: true });
}


function SaveForm(data:any) {
  if (!data.id || data.id === "") return
  if (data.social) {
    Object.keys(data.social).forEach(k => !data.social[k] && delete data.social[k])
  } else {
    data.social = {}
  }

  Object.keys(data.kurzy).map((key:string) => {
    if(!data.kurzy[key]) data.kurzy[key] = 0;
  })

  const newClen:Member = {
    hodnost: data.hodnost,
    clen_od: Timestamp.fromDate(data.clen_od),
    hodnost_order: data.hodnost_order,
    kurzy: data.kurzy,
    medals: [],
    ribbons: [],
    citations: [],
    badges: [],
    mise: {} as Map<string,any>,
    social: data.social,
  } 

  
  SaveMember(data.id,newClen);
}

function Reset(a:Function,b:Function) {
  //setSelected();
  a({});
  b([]);
}


export default function UserNew(props:any) {
  const [step,setStep] = [props.step, props.setStep];
  const [alert, setAlert] = useState({status:"",data:""});
  const [social, setSocial] = useState({});
  const [seed, setSeed] = useState(0);
  const [kurzyCall, kurzyState] = useSafeAsync<any,any>(LoadKurzy);
  const [rankCall, rankState] = useSafeAsync<any,any>(LoadRanks);
  const user = useContext(UserContext);

  useEffect(()=>{
    kurzyCall({});
    rankCall({});
  },[])


  return(<>
    {alert && alert.status && <Alert sx={{position:"fixed", top:2, right:2}} variant="filled" severity={(alert.status == "success")?"success":"error"}>{alert.data}</Alert>}
    <LoadingGuard state={kurzyState} >
      {kurzyState.value && rankState.value &&
      <>
      <FormContainer
        defaultValues={{clen_od: new Date(),email:"",id:""}}
        onSuccess={(data)=>{
          SaveForm(data);
          if (data.email && data.email != "" && data.id && data.id != "" && user?.user) {
            createUser({email:data.email, clen:data.id}).then((res:any)=>{console.log(res);setAlert(res.data); if(res.data.status == "success"){sendPasswordResetEmail(auth,data.email)}})
          }
          setSeed(seed + 1);
        }}
        key={seed}
      >
        <Stack direction={"column"} spacing={2} p={3}>
        <TextFieldElement label="Jméno" name="id" />
        <RankSelect ranks={rankState.value} />
        <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Člen od" name="clen_od" /></DateFnsProvider>

        <Typography variant="h5" pb={1} pt={5}>Kurzy</Typography>
        <Grid container spacing={1}>
          {kurzyState.value.map((key:any)=>{
            return (<Grid item key={key} xs={3}>
              <SelectElement 
                name={`kurzy[${key}]`}
                label={key}
                fullWidth
                options={[{id:"0",label:"------"},{id:"1",label:"Splnil"},{id:"2",label:"Instruktor"},]}
                defaultValue={"0"}
              />
              </Grid>
            );
          })}
        </Grid>

        <Typography variant="h5" pb={1} pt={5}>Sociální sítě</Typography>
        <Grid container spacing={1}>
          {Object.keys(social).sort().map((key,index)=>{
            return (<Grid item key={key} xs={3}><TextFieldElement  label={key} name={`social[${key}]`} /></Grid>);
          })}
        </Grid>
        <Stack direction={"row"} spacing={1}>
          <TextFieldElement name="new-social" id="new-social" />
          <Button variant="outlined" onClick={()=>{var el = document.getElementById("new-social") as HTMLInputElement; if (el.value && el.value != "") {var obj:any = {...social};obj[el.value] = "";  setSocial(obj)}}}>Přidat</Button>
        </Stack>

        <TextFieldElement name="email" id="email" label="Email" />

        <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Vložit!</LoaderButton></Box>
        </Stack>
      </FormContainer>
      </>
      }
    </LoadingGuard>
  </>)
}

function RankSelect({ranks}:{ranks:Object|Array<any>}) {
  const theme = useTheme();
  const { setValue } = useFormContext();
  
  return(<>
  <TextFieldElement type="hidden" name="hodnost" style={{display:"none"}} />
  <TextFieldElement type="hidden" name="hodnost_order" style={{display:"none"}} />
  <FormControl>
  <InputLabel id="tmp-hodnost-label" sx={{px:1,background:theme.palette.background.default}}>Hodnost</InputLabel>
  <Select
    labelId="tmp-hodnost-label"
    name="tmp-hodnost"
    onChange={(e)=>{
      const id = e.target.value as keyof typeof ranks;
      const one = ranks[id];
      setValue('hodnost', one.name);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue('hodnost_order', one.order);
    }}
    defaultValue={""}
  >
    {Object.keys(ranks).map((e:any)=>{
      const one = ranks[e as keyof typeof ranks];
      return <MenuItem value={e} key={e}>{one.name}</MenuItem>
    })}
  </Select>
  </FormControl>
  </>)
}
