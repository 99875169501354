import {firebaseConfig} from "../firebase-config.js";
import { initializeApp } from "firebase/app";
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app,{localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()})});
const storage = getStorage(app);


const vapidKey = 'BKaB5LrnZ9lfMKjgShmZljkEFJAssbi_T8vY1PQ76GI3noqymswbrNw8hsMjv2D27qC_buV_xpv7CCwr_22Nme0';

export {app, db, auth, storage, vapidKey};