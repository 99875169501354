import { Badge, Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../context/firebaseContext";
import { User as FirebaseUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Alarm, FileCopy, FolderCopy, Person, Security, SkipNext, SkipPrevious } from "@mui/icons-material";
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { UserContext } from "../context/userContext";
import Header from "../header";
import { useSafeAsync } from "../useSafeAsync";
import GetMessagingToken from "./messaging-get-token";
import { getMessaging, onMessage } from "firebase/messaging";
import { app, db } from "../firebase-private";
import { getDevice } from "../helpers";

async function CheckAdmin(id:string|undefined,set:Function) {
  if (typeof id === "undefined") return false;

  const adminColl = doc(db,'admins',id)
  const adminSnap = await getDoc(adminColl);

  return set(adminSnap.exists());
}

async function CheckUserName(uid:string|undefined,set:Function) {
  if (typeof uid === "undefined") return null;

  const userColl = doc(db,'users',uid);
  const userSnap = await getDoc(userColl);

  if (userSnap.exists()) {
    set(userSnap.data().clen)
    return userSnap.data().clen; //ziskani id prihlaseneho clena
  }
  return null;
}

async function GetAkceCount() {
  const date = new Date(); 
  date.setHours(date.getHours() - 1); // max 1 hodinu zpatky
  const akceColl = collection(db, 'akce');
  
  const akceSnapshot = await getDocs(
    query(akceColl, where('datum', '>=', date))
  );

  if (!akceSnapshot.empty) {
    return akceSnapshot.size;
  }
  return 0; 
}
async function GetDeploymentyCount() {
  const date = new Date(); 
  //date.setDate(date.getDay() - 1);
  const deploymentsColl = collection(db, 'deploymenty');
  
  //TODO: Upravit na datum_do <= nyni
  const deploymentsSnapshot = await getDocs(query(deploymentsColl, where('datum_do', '>=', date)));

  if (!deploymentsSnapshot.empty) {
    return deploymentsSnapshot.size;
  }
  return 0; 
}
async function GetMissionsCount() {
  const date = new Date(); 
  date.setHours(date.getHours() - 4); // den nazpet
  const missionsColl = query(collection(db, 'mise'), where("datum", ">=", date), orderBy("datum", "asc"))
  const missionsSnapshot = await getDocs(query(missionsColl))

  if (!missionsSnapshot.empty) {
    return missionsSnapshot.size;
  }
  return 0; 
}

if ("serviceWorker" in navigator){
  //Private mode na firefoxu nepodporuje workery
  onMessage(getMessaging(app), (payload) => {
    //Vytvoreni notifikace kdyz je appka otevrena a je v sekci pro cleny
    if (payload && payload.notification && payload.notification.title) {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.image,
        data: { url:payload.fcmOptions?.link }
      };
    
      if (Notification.permission === "granted") {
        const notification = new Notification(notificationTitle,notificationOptions);

        notification.onclick = function(event) {
          event.preventDefault(); 
          const url = this.data.url; 
          if (url) {
            window.open(url, "_blank"); 
          }
        }
      }
    }
  });
} 


export default function UserLayout(props:any) {
  const [user,setUser] = useState<FirebaseUser|null>(null)
  const [userCalled, setUserCalled] = useState<boolean>(false);
  const [userName, setUserName] = useState<string|null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [messagingToken, setMessagingToken] = useState<string|null>(window.localStorage.getItem("firebase-messaging-token"));
  const {auth} = useContext(FirebaseContext);
  const Navigate = useNavigate();

  /* Count pro Badges */
  const [akceCall, akceState] = useSafeAsync<any,any>(GetAkceCount);
  const [deploymentyCall, deploymentyState] = useSafeAsync<any,any>(GetDeploymentyCount);
  const [miseCall, miseState] = useSafeAsync<any,any>(GetMissionsCount);
  
  const isDesktop = useMediaQuery('(min-width:1000px)');
  const [expanded, setExpanded] = useState<boolean>(isDesktop);
  const cur = props.cur;
 

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(_user => {
      if(_user?.isAnonymous){setUserCalled(true); setUser(_user); return null;}
      CheckAdmin(_user?.uid,setIsAdmin).then(()=>{
        CheckUserName(_user?.uid,setUserName).then(()=>{
          setUserCalled(true);
          setUser(_user);
        })
      })
    });
    return () => unsubscribe();
  }, []);

  useEffect(()=>{
    if (userCalled && userName) {
      akceCall({});
      deploymentyCall({});
      miseCall({});

      const asyncToken = async () => {
        const memberRef = doc(db, 'messaging', userName);
        const tokenSnapshot = await getDoc(memberRef) //token z DB)
        if ("serviceWorker" in navigator && !tokenSnapshot.exists()) {
          GetMessagingToken({
            userName: userName,
            oldToken: window.localStorage.getItem("firebase-messaging-token") || undefined
          })
          .then((newToken)=>{
            if(newToken){
              setMessagingToken(newToken);
              window.localStorage.setItem("firebase-messaging-token", newToken);
            }
          })
        }
      }
      asyncToken();
    }
  },[userCalled])

  if (!userCalled) {
    return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100vw'} height={'100vh'}><CircularProgress /></Box>
  }

  //ocharna proti anon users a neprihlasenym
  if (userCalled && !userName) {
    Navigate("/");
    return <></>;
  }



  return(
  <>
  <UserContext.Provider value={{user,setUser, userCalled, isAdmin, userName}}>
    <Header title={(props.title)?`${props.title} - 44. Ravens Regiment`:null} description={props.description}/>
    <Stack direction={'row'}>
      <Box minHeight={"100vh"} maxWidth={(expanded)?"450px":"75px"} component={Paper} display={"flex"} flexDirection={"column"}>
        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} pr={2}>
          <IconButton aria-label="show/hide" edge="end" onClick={()=>setExpanded(!expanded)}>{(expanded)?<SkipPrevious />:<SkipNext />}</IconButton>
        </Box>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={()=>Navigate("/pro-cleny")}>
              <ListItemIcon>
                <img src="/logo512.png" height={50} />
              </ListItemIcon>
              {(expanded)?<ListItemText primary="44. Ravens" />:<></>}
            </ListItemButton>
          </ListItem>

          {(isAdmin)?
            <ListItem disablePadding>
              <ListItemButton onClick={()=>Navigate("/admin")}>
                <ListItemIcon><Security /></ListItemIcon>
                {(expanded)?<ListItemText primary="Admin" />:<></>}
              </ListItemButton>
            </ListItem>
          :<></>}

          <ListItem disablePadding>
            <ListItemButton onClick={()=>Navigate("/akce")} selected={(cur==="akce")}>
              <ListItemIcon>
                <Badge badgeContent={(akceState.value)?akceState.value:0} color="secondary">
                  <Alarm />
                </Badge>
              </ListItemIcon>
              {(expanded)?<ListItemText primary="Akce" />:<></>}
            </ListItemButton>
          </ListItem>
          
          <ListItem disablePadding >
            <ListItemButton onClick={()=>Navigate("/deployment")} selected={(cur==="deployment")}>
              <ListItemIcon>
                <Badge badgeContent={(deploymentyState.value)?deploymentyState.value:0} color="secondary">
                  <FolderCopy />
                </Badge>
              </ListItemIcon>
              {(expanded)?<ListItemText primary="Deploymenty" />:<></>}
            </ListItemButton>
          </ListItem>
          
          <ListItem disablePadding >
            <ListItemButton onClick={()=>Navigate("/mise")} selected={(cur==="mise")}>
              <ListItemIcon>
                <Badge badgeContent={(miseState.value)?miseState.value:0} color="secondary">
                  <FileCopy />
                </Badge>
              </ListItemIcon>
              {(expanded)?<ListItemText primary="Mise" />:<></>}
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List sx={{marginTop:'auto'}}>
          <ListItem disablePadding >
            <ListItemButton onClick={()=>Navigate("/profile")} selected={(cur==="profile")}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              {(expanded)?<ListItemText primary="Profil" />:<></>}
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box width={"100%"}>
        {props.children}
      </Box>
    </Stack>
  </UserContext.Provider>
  </>
  )
}
