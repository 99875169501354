import { Typography, Stack, Box, Button } from "@mui/material";
import { cs } from "date-fns/locale";
import { useEffect, useState } from "react";
import { FormContainer, TextFieldElement, DateTimePickerElement, SelectElement, TextareaAutosizeElement } from "react-hook-form-mui";
import DateFnsProvider from "../DateFnsProvider";
import LoadingGuard from "../loadingGuard";
import { useSafeAsync } from "../useSafeAsync";
import { Timestamp, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase-private";
import { PlatoonsElements } from "./defaultSlotsForm";
import { GearFormElement, ImagesFormElement } from "../gearFormElement";
import LoaderButton from "../loaderButton";


async function SaveForm(deployment:string,mission:string,data:any) {
  if (data.datum !== ""){
    data.datum = Timestamp.fromDate(data.datum)
  }else{
    data.datum = Timestamp.fromDate(new Date("01-01-1970"))
  }

  Object.keys(data).map((i)=>{
    if(data[i] === undefined) data[i] = "";
    if (typeof data[i] === "object") {
      Object.keys(data[i]).map((j)=>{
        if(data[i][j] === undefined) data[i][j] = "";
      })
    }
  })


  
  const memberRef = doc(db,'deploymenty',deployment,'mise',mission);
  setDoc(memberRef, data, { merge: true });
  
}

async function LoadMissions({deployment}:{deployment:string}) {
  const missionsColl = collection(db,'deploymenty',deployment,'mise');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}

async function LoadDeployments() {
  const missionsColl = collection(db,'deploymenty');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}

async function LoadMission({deployment,mission}:{deployment:string,mission:string}) {
    const missionColl = doc(db,'deploymenty',deployment,'mise',mission);
    const missionSnap = await getDoc(missionColl);

    if (missionSnap.exists()) {
      var obj = missionSnap.data();
      
      obj.id = missionSnap.id;
      obj.datum = new Timestamp(obj.datum.seconds,obj.datum.nanoseconds).toDate()
      
      if (obj.gear_odkaz) {
        obj.gear = obj.gear_odkaz;
      }

      console.log(obj);
      return obj;
    
    }
    return null;
  }

export default function MissionEdit({step,setStep,mission,deployment}:{step:number, setStep:Function,mission?:string|null,deployment?:string|null}) {
  const [missionsCall, missionsState] = useSafeAsync<any,any>(LoadMissions);
  const [deploymentsCall, deploymentsState] = useSafeAsync<any,any>(LoadDeployments);
  const [missionCall, missionState] = useSafeAsync<any,any>(LoadMission);
  const [_mission,setMission] = useState((mission)?mission:"");
  const [_deployment,setDeployment] = useState((deployment)?deployment:"");
  const [formSent,setFormSent] = useState(false);
  const [missionId,setMissionId] = useState('');

  useEffect(()=>{
    if(_deployment !== "" && _mission !== ""){
      missionCall({deployment:_deployment,mission:_mission})
    }else if(_deployment !== "" ){
      missionsCall({deployment:_deployment});
    }else{
      deploymentsCall({});
    } 
  },[_mission,_deployment])
  


  if (formSent) {
    setTimeout(function(){ window.location.reload(); }, 5000);
    return(<>
    <Typography variant="h4">Formulář odeslán</Typography>
    </>)
  }

  if (_mission === "" && _deployment === "") {
    return(<>
      <LoadingGuard state={deploymentsState} >
        {deploymentsState.value &&
        <Box p={2}>
        <FormContainer
          defaultValues={{}}
        >
            <SelectElement
            label="Deployment"
            name="deployment"
            fullWidth={true}
            options={deploymentsState.value}
            onChange={(val)=>setDeployment(val)}
          />
        </FormContainer>
        </Box>
        }
      </LoadingGuard>
    </>);
  }else if(_mission === ""){
    return(<>
      <LoadingGuard state={missionsState} >
        {missionsState.value &&
        <Box p={2}>
        <FormContainer
          defaultValues={{}}
        >
            <SelectElement
            label="Mise"
            name="mission"
            fullWidth={true}
            options={missionsState.value}
            onChange={(val)=>setMission(val)}
          />
        </FormContainer>
        </Box>
        }
      </LoadingGuard>
    </>);
  }else{
    return(
    <LoadingGuard state={missionState}>
      {missionState.value &&
      <FormContainer
        onSuccess={(data)=>{SaveForm(_deployment,_mission,data).then(()=>{setFormSent(true)})}}
        defaultValues={missionState.value}
      >
        <Stack direction={"column"} spacing={2} p={3}>
        <SelectElement
          label="Dokončený"
          name="dokoncena"
          fullWidth={true}
          options={[{id:'ano',label:'Ano'},{id:'ne',label:'Ne'}]}
          
        />
        <TextFieldElement label="Název mise" name="nazev" />
        <Typography variant="body1">{missionId}</Typography>
        <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum konání" name="datum"/></DateFnsProvider>
        <TextFieldElement label="Povinné" name="povinne" value={"OK"}/>
        <TextFieldElement label="Povolené" name="povolene" />
        <TextFieldElement label="Zakázané" name="zakazane" />
        <TextareaAutosizeElement label="Dodatečné" name="dodatecne" />
        <GearFormElement />
        <TextFieldElement label="Briefing" name="briefing" />
        <ImagesFormElement/>

        <PlatoonsElements slots={missionState.value.sloty}/>

        <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Uložit změny!</LoaderButton></Box>
        </Stack>
      </FormContainer>
      }
    </LoadingGuard>
    )
  }
}

