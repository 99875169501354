import { Typography, Stack, Box, Button } from "@mui/material";
import { cs } from "date-fns/locale";
import { useEffect, useState } from "react";
import { FormContainer, TextFieldElement, DateTimePickerElement, SelectElement, CheckboxElement } from "react-hook-form-mui";
import DateFnsProvider from "../DateFnsProvider";
import LoadingGuard from "../loadingGuard";
import { useSafeAsync } from "../useSafeAsync";
import { Timestamp, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase-private";
import LoaderButton from "../loaderButton";


async function SaveForm(deployment:string,data:any) {
  
  if (data.datum_od !== ""){
    data.datum_od = Timestamp.fromDate(data.datum_od)
  }else{
    data.datum_od = Timestamp.fromDate(new Date("01-01-1970"))
  }
  
  if (data.datum_do !== ""){
    data.datum_do = Timestamp.fromDate(data.datum_do)
  }else{
    data.datum_do = Timestamp.fromDate(new Date("01-01-1970"))
  }

  const memberRef = doc(db,'deploymenty',deployment);
  setDoc(memberRef, data, { merge: true });
}

async function LoadDeployment({deployment}:{deployment:string}) {
  const deploymentColl = doc(db,'deploymenty',deployment);
    const deploymentSnap = await getDoc(deploymentColl);

    if (deploymentSnap.exists()) {
      var obj = deploymentSnap.data();
      
      obj.id = deploymentSnap.id;
      obj.datum_do = new Timestamp(obj.datum_do.seconds,obj.datum_do.nanoseconds).toDate()
      obj.datum_od = new Timestamp(obj.datum_od.seconds,obj.datum_od.nanoseconds).toDate()

      return obj;
    }
    return null;
}

async function LoadDeployments() {
  const deploymentsColl = collection(db,'deploymenty');
  const deploymentsSnap = await getDocs(deploymentsColl);

  var deployments:any[] = [];
  deploymentsSnap.forEach((doc)=>{
    deployments.push({id:doc.id,label:doc.id});
  })
  
  return deployments;
}

export default function DeploymentEdit({step,setStep,id}:{step:number, setStep:Function,id?:string|null}) {

  const [deploymentsCall, deploymentsState] = useSafeAsync<any,any>(LoadDeployments);
  const [deploymentCall, deploymentState] = useSafeAsync<any,any>(LoadDeployment);
  const [deployment,setDeployment] = useState((id)?id:"");
  const [formSent,setFormSent] = useState(false);

  useEffect(()=>{
    if(deployment !== "" ){
      deploymentCall({deployment:deployment});
    }else{
      deploymentsCall({});
    } 
  },[deployment])
  


  if (formSent) {
    setTimeout(function(){ window.location.reload(); }, 5000);
    return(<>
    <Typography variant="h4">Formulář odeslán</Typography>
    </>)
  }

  if (deployment === "") {
    return(<>
      <LoadingGuard state={deploymentsState} >
        {deploymentsState.value &&
        <Box p={2}>
        <FormContainer
          defaultValues={{}}
        >
            <SelectElement
            label="Deployment"
            name="deployment"
            fullWidth={true}
            options={deploymentsState.value}
            onChange={(val)=>setDeployment(val)}
          />
        </FormContainer>
        </Box>
        }
      </LoadingGuard>
    </>);
  }else {
    return(
    <LoadingGuard state={deploymentState}>
      {deploymentState.value &&
      <FormContainer
        onSuccess={(data)=>{SaveForm(deployment,data).then(()=>{setFormSent(true)})}}
        defaultValues={deploymentState.value}
      >
        <Stack direction={"column"} spacing={2} p={3}>
        
        <TextFieldElement label="Název" name="nazev" />
          <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum od" name="datum_od" /></DateFnsProvider>
          <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum do" name="datum_do" /></DateFnsProvider>
          <TextFieldElement label="Mapa" name="mapa" />
          <TextFieldElement label="Odkaz na disk" name="disk" />
          <CheckboxElement label={"Oficiální?"} name={"oficialni"} />

          <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Uložit změny!</LoaderButton></Box>
        </Stack>
      </FormContainer>
      }
    </LoadingGuard>
    )
  }
}

