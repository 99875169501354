import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../components/firebase-public";
import { UserContext } from "../components/context/userContext";
import { useContext, useEffect } from "react";
import LoadingGuard from "../components/loadingGuard";
import { useSafeAsync } from "../components/useSafeAsync";
import { Grid, IconButton, Link, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { FolderOpen } from "@mui/icons-material";


async function GetDeployments() {
  const date = new Date(); 
  //date.setMonth(date.getMonth() - 3); // mesic nazpet
  //date.setDate(date.getDay() + 1);
  const deploymentsColl = collection(db, 'deploymenty');

  const deploymentsSnapshot = await getDocs(query(deploymentsColl, where('datum_do', '>=', date), orderBy("datum_do","asc")));
  if (!deploymentsSnapshot.empty) {
    return deploymentsSnapshot.docs.map((doc) => {return {id:doc.id,...doc.data()}} );
  }
  return [];
}

export default function DeploymentsPage(props:any) {
  const [deploymentsCall, deploymentsState] = useSafeAsync<any,any>(GetDeployments);
  const userObject = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
    deploymentsCall({});
  },[])


  return(<>
    <LoadingGuard state={deploymentsState} >
        {deploymentsState.value &&
        <>
        <Stack direction={"column"} p={4} spacing={2} >
          {deploymentsState.value.map((deployment:any,i:number)=>{
              return(
              <Link key={deployment.id+"-"+i} href={`/deployment/${deployment.id}`} style={{textDecoration:'none'}} >
                <Grid container direction={"row"} alignItems={"center"} component={Paper} px={1} py={0.5} >
                  <Grid item xs={12} md={2}>
                    <Stack direction={(isNotPhone)?"column":"row"} spacing={(isNotPhone)?0:2}>
                      <Typography variant="body2">{deployment.datum_od.toDate().toLocaleDateString("cs-CZ")}</Typography>
                      <Typography variant="body2">{deployment.datum_do.toDate().toLocaleDateString("cs-CZ")}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={10} md={9}><Typography variant="h5">{deployment.nazev}</Typography></Grid>
                  
                  <Grid item xs={1}>
                    <Link href={deployment.disk} target="__blank" title="Google Disk" >
                      <IconButton><FolderOpen /></IconButton>
                    </Link>
                  </Grid>
                  
                </Grid>
              </Link>
              );
            })
          }
        </Stack>
        </>
        }
    </LoadingGuard>
  </>)
}

