import { Link, Stack, Typography } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { analytics } from "../components/firebase-public";



export default function JoinPage() {
    
    return(<>
    <Stack direction={"column"} textAlign={"center"}>
        <Typography variant="body2" my={2}>Pokud máš zájem se k nám přidat, tak vyplň naši přihlášku a připoj se k nám na <Link onClick={()=>{logEvent(analytics,'social_link_main',{ value: 'discord' })}} href={'/discord'} target={"_blank"}>discord ZDE!</Link></Typography>
        <iframe  
        src="https://docs.google.com/forms/d/e/1FAIpQLSdkIHwmJuEj6hRCPzGnkrnqydd1xqo1Y0izx58aBFAdAqoBwg/viewform?embedded=true" 
        width="100%" height="986" style={{border:"none"}}
        >Načítání…</iframe>
    </Stack></>);
}