
import { getMessaging, getToken } from "firebase/messaging";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { app, db, vapidKey } from "../firebase-private";
import { getDevice } from "../helpers";


export default async function GetMessagingToken({userName, oldToken}:{userName:any, oldToken?:string}) {
  const messaging = getMessaging(app);

  const token = await getToken(messaging, { vapidKey: vapidKey }).then(async (currentToken) => {
    //ziskani tokenu pro messaging, currentToken = token ze zarizeni
    if (currentToken && currentToken !== oldToken) {
      console.log("Updating new registration token for messaging.");
      const memberRef = doc(db, 'messaging', userName);
      await setDoc(memberRef, {token:currentToken, device: getDevice()})
      return currentToken;
    } else if(currentToken && currentToken === oldToken){
      return currentToken;
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      return null;
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    return null;
  });    


  return token;
}
