import { useEffect, useState } from "react";
import { useSafeAsync } from "../useSafeAsync";
import {db} from "../firebase-private";
import LoadingGuard from "../loadingGuard";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { CheckboxElement, FormContainer, SelectElement, TextFieldElement, } from "react-hook-form-mui";
import { defaultRangersSlots } from "./defaultSlots";
import LoaderButton from "../loaderButton";


async function SaveForm({users, id, deployment, type}:{users:Record<string, any>, id:string, deployment?:string, type?:string}) {
  
  await Promise.all(
    Object.keys(users).map(async (user:string)=>{
      const one = users[user];
      if (! one.approved ) return;
      delete one.approved;
      if (deployment) {
        // ukladat deployment jako vlastni document
        await setDoc(doc(db,'clenove',user,'mise',deployment), {
          [id]:one
        },{merge:true});
      } else {
        // solo mise ukladat dle typu
        switch (type) {
          case "o":
            await setDoc(doc(db,'clenove',user,'mise','oficialni'), {
              [id]:one
            },{merge:true});
            break;
          case "t":
            await setDoc(doc(db,'clenove',user,'mise','trenink'), {
              [id]:one
            },{merge:true});
            break;
          case "n":
            await setDoc(doc(db,'clenove',user,'mise','neoficialni'), {
              [id]:one
            },{merge:true});
            break;
        
          default:
            break;
        }
      }
    })
  )

  return true;
}


async function LoadMissions({deploymentId}:{deploymentId:string}) {
  const missionsColl = collection(db,'deploymenty',deploymentId,'mise');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}
async function LoadDeployments() {
  const missionsColl = collection(db,'deploymenty');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}

async function LoadSoloMissions() {
  const missionsColl = collection(db,'mise');
  const missionsSnap = await getDocs(missionsColl);

  var missions:any[] = [];
  missionsSnap.forEach((doc)=>{
    missions.push({id:doc.id,label:doc.id});
  })
  
  return missions;
}

async function LoadMission({deployment,id}:{id:string,deployment:string}) {
    const missionColl = doc(db,'deploymenty',deployment,'mise',id);
    const missionSnap = await getDoc(missionColl);

    if (missionSnap.exists()) {
      var obj = missionSnap.data();

      if (obj.stav !== "dokoncena") {
        const userColl = collection(db,'users');
        const userSnap = await getDocs(userColl);

        var users:any[] = [{id:"",label:"Nikdo"}];
        userSnap.forEach((doc)=>{
          users.push({id:doc.data().clen,label:doc.data().clen});
        })
        obj.members = users;
      }

      //pokud je Rangers ORBAT
    if (Object.keys(obj.sloty).includes("MISFIT")){
      const order = Object.keys(defaultRangersSlots);
      
      Object.keys(obj.sloty).map((plat:any)=>{
        if (obj.sloty[plat] ) { //pokud existuje platoon
          if (defaultRangersSlots[plat as keyof typeof defaultRangersSlots]) {
            let order = Object.keys(defaultRangersSlots[plat as keyof typeof defaultRangersSlots])
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort((a:any,b:any)=>{return(order.indexOf(a) - order.indexOf(b))}) //seradit jednotlive sloty A-A-1, A-A-2, atd... - PODLE ORBAT
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }else{
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort() //seradit jednotlive sloty A-A-1, A-A-2, atd... - ABECEDNE
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }
        }
      })

      obj.sloty = Object.keys(obj.sloty).sort((a:any,b:any)=>{
        if (order.includes(a) && order.includes(b)) {
          return order.indexOf(a) - order.indexOf(b);
        }else if (!order.includes(a)) {
          return 1; // Move 'a' to the end
        }else if (!order.includes(b)) {
          return -1; // Keep 'a' before 'b'
        }
        return -1;
      }) //seradit platoony dle ORBAT a nebo je dej na konec
        .reduce(function (acc:any, key) { 
          acc[key] = obj.sloty[key];
          return acc;
        }, {});
        
    }
     
      return obj;
    
    }
  return null;
}

async function LoadDeployment({deployment}:{deployment:string}) {
    const deploymentColl = doc(db,'deploymenty',deployment);
    const deploymentSnap = await getDoc(deploymentColl);

    if (deploymentSnap.exists()) {
      var obj = deploymentSnap.data();
      return obj; 
    }
  return null;
}

async function LoadSoloMission({id}:{id:string}) {
  const missionColl = doc(db,'mise',id);
  const missionSnap = await getDoc(missionColl);

  if (missionSnap.exists()) {
    var obj = missionSnap.data();

    if (obj.stav !== "dokoncena") {
      const userColl = collection(db,'users');
      const userSnap = await getDocs(userColl);

      var users:any[] = [{id:"",label:"Nikdo"}];
      userSnap.forEach((doc)=>{
        users.push({id:doc.data().clen,label:doc.data().clen});
      })
      obj.members = users;
    }

    //pokud je Rangers ORBAT
    if (Object.keys(obj.sloty).includes("MISFIT")){
      const order = Object.keys(defaultRangersSlots);
      
      Object.keys(obj.sloty).map((plat:any)=>{
        if (obj.sloty[plat] ) { //pokud existuje platoon
          if (defaultRangersSlots[plat as keyof typeof defaultRangersSlots]) {
            let order = Object.keys(defaultRangersSlots[plat as keyof typeof defaultRangersSlots])
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort((a:any,b:any)=>{return(order.indexOf(a) - order.indexOf(b))}) //seradit jednotlive sloty A-A-1, A-A-2, atd... - PODLE ORBAT
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }else{
            obj.sloty[plat] = Object.keys(obj.sloty[plat])
              .sort() //seradit jednotlive sloty A-A-1, A-A-2, atd... - ABECEDNE
              .reduce(function (acc:any, key) { //tohle udela z keys zase puvodni objecta le serazeny podle keys
                acc[key] = obj.sloty[plat][key];
                return acc;
              },  {});
          }
        }
      })

      obj.sloty = Object.keys(obj.sloty).sort((a:any,b:any)=>{
        if (order.includes(a) && order.includes(b)) {
          return order.indexOf(a) - order.indexOf(b);
        }else if (!order.includes(a)) {
          return 1; // Move 'a' to the end
        }else if (!order.includes(b)) {
          return -1; // Keep 'a' before 'b'
        }
        return -1;
      }) //seradit platoony dle ORBAT a nebo je dej na konec
        .reduce(function (acc:any, key) { 
          acc[key] = obj.sloty[key];
          return acc;
        }, {});
        
    }

    return obj;
  
  }
  return null;
}

export default function MissionFinish({step,setStep,mission,deployment}:{step:number, setStep:Function, mission?:string|null, deployment?:string|null}) {
  // Data
  const [missionCall, missionState] = useSafeAsync<any,any>(LoadMission);
  const [deploymentCall, deploymentState] = useSafeAsync<any,any>(LoadDeployment);
  const [soloMissionCall, soloMissionState] = useSafeAsync<any,any>(LoadSoloMission);

  // Select
  const [deploymentsCall, deploymentsState] = useSafeAsync<any,any>(LoadDeployments);
  const [missionsCall, missionsState] = useSafeAsync<any,any>(LoadMissions);
  const [soloMissionsCall, soloMissionsState] = useSafeAsync<any,any>(LoadSoloMissions);

  const [_mission,setMission] = useState((deployment)?mission:"");
  const [soloMission,setSoloMission] = useState((!deployment && mission)?mission:"");
  const [_deployment,setDeployment] = useState((deployment)?deployment:"");
  

  useEffect(()=>{
    if(_deployment === "" && _mission === "" && soloMission === ""){
      deploymentsCall({deploymentId:_deployment});
      soloMissionsCall({});
    }else if(_deployment !== "" && _mission !== ""){
      missionCall({deployment:_deployment,id:_mission});
      deploymentCall({deployment:_deployment});
    }else if(soloMission !== ""){
      soloMissionCall({id:soloMission});
    } else {
      missionsCall({deploymentId:_deployment});
    }
  },[_mission,_deployment,soloMission])


  if (_deployment === "" && _mission === "" && soloMission === "") {
    return(<>
      <LoadingGuard state={deploymentsState} >
        {deploymentsState.value && soloMissionsState.value &&
        <>
        <FormContainer
          defaultValues={{}}
        >
          <Stack p={2} spacing={1}>
          <SelectElement
            label="Deployment"
            name="deployment"
            fullWidth={true}
            options={deploymentsState.value}
            onChange={(val)=>setDeployment(val)}
          />
          <SelectElement
            label="Neoficiální mise"
            name="neofiko-mise"
            fullWidth={true}
            options={soloMissionsState.value}
            onChange={(val)=>setSoloMission(val)}
          />
          </Stack>
        </FormContainer>
        </>
        }
      </LoadingGuard>
    </>);
  }else if (_mission === "" && soloMission === "") {
    return(<>
      <LoadingGuard state={missionsState} >
        {missionsState.value &&
        <Box p={2}>
          <FormContainer
            defaultValues={{}}
          >
            <SelectElement
              label="Mise"
              name="mission"
              fullWidth={true}
              options={missionsState.value}
              onChange={(val)=>setMission(val)}
            />
          </FormContainer>
        </Box>
        }
      </LoadingGuard>
    </>);
  }else if(_deployment === "" && _mission === "") {
    return (
    <LoadingGuard state={soloMissionState} >
      { soloMissionState.value &&
        <FinnishForm slots={soloMissionState.value.sloty} id={soloMissionState.value.id} type={soloMissionState.value.typ}/>
      }
    </LoadingGuard>
    )
  } else {
    return(<>
      <LoadingGuard state={missionState} >
        { missionState.value && deploymentState.value &&
          <FinnishForm slots={missionState.value.sloty} id={missionState.value.id} type={missionState.value.typ}  _deployment={missionState.value.deployment} official={deploymentState.value.oficialni}/>
        }
      </LoadingGuard>
    </>)
    }
  }

function FinnishForm({slots, id, type, _deployment, official}:any) {
  const [formSent, setFormSent] = useState(false);
  const users = Object.keys(slots).map((platoon)=>{
    return Object.keys(slots[platoon]).map((slot)=>{
      if (slots[platoon][slot].povoleno && slots[platoon][slot].clen) {
        const one = slots[platoon][slot];
        return {platoon: platoon, slot: slot, user: one.clen, kit: one.kit};
      }
    }).filter(item => item !== undefined)
  }).flatMap(array => array);

  const defaultValues={
    users: users.reduce((acc:any, item:any, index) => {
      acc[item.user] = {platoon: item.platoon, slot: item.slot, kit: item.kit, approved: true};
      return acc;
  }, {})
  }

  if (_deployment) {
    type = (official)?"o":"n"; 
  }

  if (formSent) {
    setTimeout(function(){ window.location.reload(); }, 3000);
    return(<>
    <Typography variant="h4">Formulář odeslán</Typography>
    </>)
  }


  return(
    <FormContainer
      defaultValues={defaultValues}
      onSuccess={(data)=>{
          SaveForm({users: data.users, id: id, deployment: _deployment, type: type}
        ).then(()=>{
          setFormSent(true);
        })
      }}
    >
      <Stack direction={"column"} spacing={2} margin={1}>
        {(_deployment)?<Typography variant="h4">{_deployment} / {id}</Typography>:<Typography variant="h3">{id}</Typography>}
        <Typography variant="h5">Typ: {(type == "n")?"Neoficiální":((type == "t")? "Trénink" : "Oficiální")}</Typography>

        { users.map((one:any)=>{
          return (
            <Stack direction={"row"} spacing={1} key={one!.user} alignItems={"center"}>
              <CheckboxElement name={`users[${one.user}].approved`} color={"success"} />
              <TextField value={one.user} label="Clen" disabled />
              <TextFieldElement name={`users[${one.user}].platoon`} label="Platoon" />
              <TextFieldElement name={`users[${one.user}].slot`} label="Slot" />
              <TextFieldElement name={`users[${one.user}].kit`} label="Kit" />
            </Stack> 
          )
        })}
        
        <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Vložit!</LoaderButton></Box>
      </Stack>
    </FormContainer>
  )
}
  
  