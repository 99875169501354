import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { auth, db } from "../components/firebase-public";
import LoadingGuard from "../components/loadingGuard";
import { useSafeAsync } from "../components/useSafeAsync";
import { useContext, useEffect } from "react";
import { UserContext } from "../components/context/userContext";
import { Controller, FormContainer, useFieldArray, useFormContext } from "react-hook-form-mui";
import { Autocomplete, Box, Button, Grid, IconButton, InputAdornment, Link, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { CustomObjectSort } from "../components/helpers";
import { Close, Add } from "@mui/icons-material";
import { sendPasswordResetEmail } from "firebase/auth";
import GetMessagingToken from "../components/user-zone/messaging-get-token";


function getDefaultValues(value: any): any {
  const date = new Timestamp(value.clen_od.seconds,value.clen_od.nanoseconds)
  type defValueT = {
    clen_od: Date,
    social : Array<any>
  }
  let defVal:any = {
    clen_od:date.toDate(),
    social: []
  } as defValueT;

  Object.keys(value.social).map((key,index)=>{
    defVal.social.push({name: key, value: value.social[key]})
  })

  return defVal
}

async function LoadMember(id:string) {
  const membersColl = doc(db,'clenove',id);

  const memberSnap = await getDoc(membersColl);
  if (memberSnap.exists()) {
    var obj = memberSnap.data();
    obj!.id = memberSnap.id;
    return obj;
  }
  
  return null;
}

function SaveForm(data:any,username:string) {
  const newSocial:any = {};
  data.social.map((one:any)=>{
    newSocial[one.name] = one.value;
  })
  
  const newData = {
    social: newSocial
  };

  const memberRef = doc(db, 'clenove', username);
  setDoc(memberRef, newData, { merge: true }).then(()=>{  window.location.reload();  });

}

function ResetPassword(user:any) {
  sendPasswordResetEmail(auth,user.email);
}

export default function UserProfilePage() {
  const [memberCall, memberState] = useSafeAsync<any,any>(LoadMember);
  const user = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
    if (user?.userName) {
      memberCall(user.userName);
    }
  },[user?.userName])

  return(
  <LoadingGuard state={memberState} >
    {user && memberState.value &&
    <>
    <Typography variant="h3" m={2}>{user.userName}</Typography>
      <Stack direction={"column"} spacing={2} p={3}>
        <Typography variant="body1">Členem od: {memberState.value.clen_od.toDate().toLocaleDateString("cs-CZ")}</Typography>

        <ListKurzy username={user.userName} />

        <FormContainer
          defaultValues={getDefaultValues(memberState.value)}
          onSuccess={(data)=>{SaveForm(data,user.userName!)}}
        >
          <SocialsFormGroup />
        </FormContainer>

        <NotificationGroup />

        <Stack direction={isNotPhone?"row":"column"} spacing={1} justifyContent="center" >

          <Button type="button" onClick={()=>{ResetPassword(user.user)}} variant="outlined">Resetovat heslo</Button>
        </Stack>
      </Stack>
      </>
    }
  </LoadingGuard>
  );
}



// --------------------- KURZY ----------------------------------

async function LoadKurzy({username}:{username:string}) {
  var min_hodnosti:any = {}
  const kurzyColl = doc(db,'kurzy','podle_hodnosti');
  const kurzySnap = await getDoc(kurzyColl);
  if (kurzySnap.exists()) min_hodnosti = kurzySnap.data();

  const userColl = doc(db,'clenove',username);
  var clen:any = {}
  
  const userSnap = await getDoc(userColl);
  if (userSnap.exists()) clen = userSnap.data();

  if(!clen) return null;

  const hodnost = clen.hodnost_order;
  Object.keys(clen.kurzy).forEach((key)=>{
    if(clen.kurzy[key] == 0 && min_hodnosti[key] <= hodnost){ // == protoze 0 ale i '0'
      clen.kurzy[key] = '3';
    }else if (clen.kurzy[key] == 0){ 
      clen.kurzy[key] = '4';
    }
    clen.kurzy[key] = clen.kurzy[key].toString();
  });
  return CustomObjectSort(clen.kurzy)


}

function ListKurzy(props:any) {
  const [kurzyCall, kurzyState] = useSafeAsync<any,any>(LoadKurzy);
  const userObj = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
    kurzyCall({username:props.username})
  },[props.username])

  return (<>
    <LoadingGuard state={kurzyState}>
      {kurzyState.value && <>
        <Stack direction={"row"}>
          <Typography variant="caption" color={"#0f4d1c"} mr={1}>Splněný kurz</Typography>
          <Typography variant="caption" color={"#6c6c0c"} mr={1}>Instruktor</Typography>
          <Typography variant="caption" color={"#1a627d"} mr={1}>Má hodnost pro kurz</Typography>
          <Typography variant="caption" color={"#6c0c0c"}>Nemůže dělat kurz</Typography>
        </Stack>
        <Grid container spacing={1} gap={(isNotPhone)?1:2} justifyContent={(isNotPhone)?"flex-start":"space-evenly"} mt={1}>
          {Object.keys(kurzyState.value).map((key:any, i:any)=>{
            let style={}
            switch (kurzyState.value[key]) {
              case '1': // uz ma kurz
                style={background:'#0f4d1c'}
                break;
              case '2': // je instruktor
                style={background:'#6c6c0c'}
                break;
              case '3': // muze udelat
                style={background:'#1a627d'}
                break;
              case '4': //nemuze udelat
                style={background:'#6c0c0c'}
                break;
            
              default: // 0 - nema kurz
                style={background:'#6c0c0c'}
                break;
            }
            return(
              <Grid key={key} item xs={4} md={1.8} sx={{padding:"0 !important",...style}} justifyContent={"space-evenly"}>
                <Link href={"/pro-cleny/hledani-kurzu/"+key} style={{ textDecoration: 'none' }}><Typography variant="body2" sx={{textAlign:'center'}} p={1}>{key.toUpperCase()}</Typography></Link>
              </Grid>
            )
          })}
        </Grid>
      </>}
    </LoadingGuard>
  </>);
}

// --------------------------------------------------------------

function SocialsFormGroup() {
  const form = useFormContext();
  const control = form.control;
  const register = form.register;
  const isNotPhone = useMediaQuery('(min-width:600px)');

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "social", // unique name for your Field Array
  });


  return(<>
    <Typography variant="h5" pb={1} pt={5}>Sociální sítě</Typography>
    <Grid container spacing={1}>
      {fields.map((field:any, index) => {
        return (
          <Grid item key={field.id} xs={12}>
            <Stack direction={isNotPhone?"row":"column"} spacing={1}>
              <Controller
                name={`social.${index}.name`}
                control={control}
                defaultValue={{ label: "yt", value: "yt" }} // Set your default value here
                render={({ field: { onChange, value } }) =>
                  <Autocomplete
                    id={`auto-${index}`}
                    options={[
                      {label:"yt",value:"yt"},
                      {label:"insta",value:"insta"},
                      {label:"twitch",value:"twitch"},
                    ]}
                    value={value}
                    sx={{width:isNotPhone?"300px":"200px"}}
                    renderInput={(params:any) => <TextField
                        {...params}
                        label={"Název"}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="remove"
                              onClick={() => remove(index)}
                              onMouseDown={(e)=>{e.preventDefault()}}
                              edge="end"
                            >
                              <Close color="error" />
                            </IconButton>
                          </InputAdornment>
                        }}
                      />
                    }
                  />
                }
              />
            
            <TextField
              {...register(`social.${index}.value`)}
              label={"URL"}
              fullWidth
            />
            </Stack>
          </Grid>
        );
      })}
    </Grid>
    
    <Stack direction={isNotPhone?"row":"column"} spacing={1} margin={1} justifyContent="center" >
      <Button variant="outlined" onClick={()=>{append({name:"", value:""})}}><Add /> Social</Button>
      <Button type="submit" variant="outlined">Uložit</Button>
    </Stack>
  </>)
}

// -------------------- NOTIFIKACE -----------------
async function LoadNotify({userName}:{userName:string}) {
  const notifyColl = doc(db,'messaging',userName);

  const notifySnap = await getDoc(notifyColl);
  if (notifySnap.exists()) {
    var obj = notifySnap.data();
    obj!.id = notifySnap.id;
    return obj;
  }
  
  return {};
}

function NotificationGroup() {
  const [notifyCall, notifyState] = useSafeAsync<any,any>(LoadNotify);
  const user = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');
  const isGranted = (Notification.permission == "granted");

  useEffect(()=>{
    if (user) {
      notifyCall({userName:user.userName})
    }
  },[])
  

  return(<>
  <Typography variant="h6">Správa notifikací</Typography>
  <LoadingGuard state={notifyState} >
    {user && notifyState.value && 
      <Stack direction="column" alignItems={"center"} spacing={1} justifyContent="center" >
        {(notifyState.value.device)?<Typography variant="body2">Aktuálně nastavené pro {notifyState.value.device}</Typography>:<></>}
        <Button type="button" 
          onClick={()=>{
            Notification.requestPermission().then((permission) =>{
              if (permission == "granted") {
                GetMessagingToken({
                  userName: user.userName,
                })
              }
            })
          }} 
          variant="outlined" 
        >
          {(isGranted)?"Znovu povolit notifikace":"Povolit push notifikace"}
        </Button>
      </Stack>
    }
  </LoadingGuard>
  </>
  );
}