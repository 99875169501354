import { useEffect } from "react";
import { useSafeAsync } from "../components/useSafeAsync";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../components/firebase-public";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, csCZ } from '@mui/x-data-grid';



async function LoadMembers() {
  const coll = collection(db, 'clenove');
  const snapshot = await getDocs(coll);

  const out:Array<any> = []
  snapshot.forEach((doc) => {
    const data = doc.data();
    out.push({id:doc.id,kurzy:data.kurzy});
  });
  return out;
}
async function LoadKurzy() {
  const coll = doc(db,'kurzy','podle_hodnosti')
  const snap = await getDoc(coll);

  const out:Array<any> = []
  if (snap.exists()) {
    const data = snap.data();
    Object.keys(data).map((e:any)=>{out.push(e);return true})
  }
  return out.sort();
}

export default function AdminPage(pros:any) {
  const [membersCall, membersState] = useSafeAsync<any,any>(LoadMembers);
  const [kurzyCall, kurzyState] = useSafeAsync<any,any>(LoadKurzy);
  
  useEffect(()=>{
    membersCall({});
    kurzyCall({});
  },[])



  if (kurzyState.value && membersState.value) {

    const columns: GridColDef[] = [];
    columns.push({field: 'name', headerName: 'User', width: 200})
    kurzyState.value.map((kurz:any)=>{
      columns.push({
        field: kurz,
        headerName: kurz,
        width: 100,
        cellClassName: (params: GridCellParams<any, number>) => {
          if (params.value == null) {
            return '';
          }
          if (params.value == 1) {
            return 'green';
          } else if(params.value == 2) {
            return 'blue';
          } else if(params.value == 3) {
            return 'yellow';
          } else {
            return 'red';
          }
        },
      })
    })
    
    const rows:Array<any> = membersState.value.map((one:any)=>{
      var ret = {id:one.id, name: one.id}
      kurzyState.value.map((kurz:any)=>{
        ret[kurz as keyof typeof ret] = one.kurzy[kurz];
      })
      return ret;
    })

    return(
      <Box sx={{ height: '100%', 
        width: '100%',
        '& .green': {
          backgroundColor: '#07ff0029',
          fontWeight: '600',
        },
        '& .blue': {
          backgroundColor: '#00c2fb29',
          fontWeight: '600',
        },
        '& .yellow': {
          backgroundColor: '#f7ff0029',
          fontWeight: '600',
        },
        '& .red': {
          backgroundColor: '#ff000029',
          fontWeight: '600',
        },
      }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
    ) 
  } else {
    return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100vw'} height={'100vh'}><CircularProgress /></Box>
  }
  
}
