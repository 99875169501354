import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography, useTheme } from "@mui/material";
import { Timestamp, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../firebase-private";
import LoadingGuard from "../loadingGuard";
import { useSafeAsync } from "../useSafeAsync";
import { Controller, DateTimePickerElement, FormContainer, SelectElement, TextFieldElement, useFieldArray, useFormContext} from "react-hook-form-mui";
import DateFnsProvider from "../DateFnsProvider";
import cs from "date-fns/locale/cs";
import { Add, Close } from "@mui/icons-material";
import { getStorage, listAll, ref } from "firebase/storage";
import LoaderButton from "../loaderButton";



async function LoadMembers() {
  const membersColl = collection(db, 'clenove');
  const membersQuerySnapshot = await getDocs(membersColl);

  const selectOptions:Array<any> = []
  membersQuerySnapshot.forEach((doc) => {
    selectOptions.push({id:doc.id,label:doc.id});
  });
  return selectOptions;
}

async function LoadMember({id}:{id:string}) {
  const membersColl = doc(db,'clenove',id)
  const membersSnap = await getDoc(membersColl);
  const kurzyColl = doc(db,'kurzy','all')
  var kurzy:any = [];

  const kurzySnap = await getDoc(kurzyColl);
  if (kurzySnap.exists()) {
    kurzy = kurzySnap.data().all;
  }
  kurzy.sort();

  //zbytek kodu po provedeni nacteni z firebase
  if (membersSnap.exists()) {
    var obj = membersSnap.data();
    obj.id = membersSnap.id;

    //nacteni kurzu z DB
    let newKurzy:Array<any> = []
    kurzy.map((d:any)=>{
      if (!obj.kurzy[d]) newKurzy[d] = 0
      newKurzy[d] = obj.kurzy[d];
    })
    obj.kurzy = newKurzy
    return obj;
  }
  
  return null;
}

async function LoadRanks() {
  const rankColl = doc(db,'hodnosti','all')
  const rankSnap = await getDoc(rankColl);

  if (rankSnap.exists()) {
    const data = rankSnap.data();
    if (data) {
      return Object.values(data).sort((a, b) => a.order - b.order);
    }
    return []
  }else{
    return [];
  }
}

async function LoadMedals() {
  const storage = getStorage();
  const zeusRef = ref(storage, '/system/medals/');
  const ribbons:any[] = []

  await listAll(zeusRef).then((res) => {      
    res.items.forEach((itemRef) => {
      const name = itemRef.name.split(".")[0];
      ribbons.push(name);
    });      
  })
  return ribbons
}
async function LoadRibbons() {
  const storage = getStorage();
  const zeusRef = ref(storage, '/system/ribbons/');
  const ribbons:any[] = []

  await listAll(zeusRef).then((res) => {      
    res.items.forEach((itemRef) => {
      const name = itemRef.name.split(".")[0];
      ribbons.push(name);
    });      
  })
  return ribbons
}
async function LoadBadges() {
  const storage = getStorage();
  const zeusRef = ref(storage, '/system/badges/');
  const ribbons:any[] = []

  await listAll(zeusRef).then((res) => {      
    res.items.forEach((itemRef) => {
      const name = itemRef.name.split(".")[0];
      ribbons.push(name);
    });      
  })
  return ribbons
}
async function LoadCitations() {
  const storage = getStorage();
  const zeusRef = ref(storage, '/system/citations/');
  const ribbons:any[] = []

  await listAll(zeusRef).then((res) => {      
    res.items.forEach((itemRef) => {
      const name = itemRef.name.split(".")[0];
      ribbons.push(name);
    });      
  })
  return ribbons
}


function getDefaultValues(value: any): any {
  const date = new Timestamp(value.clen_od.seconds,value.clen_od.nanoseconds)
  type defValueT = {
    hodnost: string,
    hodnost_order: string,
    clen_od: Date,
    kurzy : Array<any>,
    medals : Array<any>,
    ribbons : Array<any>,
    citations : Array<any>,
    badges : Array<any>,
    social : Array<any>
  }
  let defVal:any = {
    hodnost: value.hodnost,
    hodnost_order: value.hodnost_order,
    clen_od:date.toDate(),
    kurzy: [],
    medals: [],
    ribbons: [],
    citations: [],
    badges: [],
    social: []
  } as defValueT;
  
  Object.keys(value.kurzy).map((key,index)=>{
    defVal.kurzy.push({name: key, value: (value.kurzy[key])?value.kurzy[key]:0})
  })

  if(value.medals){
    Object.keys(value.ribbons).map((key,index)=>{
      defVal.ribbons.push({name: value.ribbons[key]})
    })
    Object.keys(value.medals).map((key,index)=>{
      defVal.medals.push({name: value.medals[key]})
    })
    Object.keys(value.badges).map((key,index)=>{
      defVal.badges.push({name: value.badges[key]})
    })
    Object.keys(value.citations).map((key,index)=>{
      defVal.citations.push({name: value.citations[key]})
    })
  }
  
  Object.keys(value.social).map((key,index)=>{
    defVal.social.push({name: key, value: value.social[key]})
  })

  return defVal
}

function SaveForm(data:any,id:string) {
  data.clen_od = Timestamp.fromDate(data.clen_od)
  
  data.ribbons = Object.keys(data.ribbons).map((key:any)=>{
    return data.ribbons[key].name;
  })
  data.medals = Object.keys(data.medals).map((key:any)=>{
    return data.medals[key].name;
  })
  data.badges = Object.keys(data.badges).map((key:any)=>{
    return data.badges[key].name;
  })
  data.citations = Object.keys(data.citations).map((key:any)=>{
    return data.citations[key].name;
  })

  var newSocial:any = {};
  data.social = Object.keys(data.social).map((key:any)=>{
    newSocial[data.social[key].name] = data.social[key].value;
  })
  data.social = newSocial;

  var newKurzy:any = {};
  data.kurzy = Object.keys(data.kurzy).map((key:any)=>{
    newKurzy[data.kurzy[key].name] = (data.kurzy[key].value !== "")?data.kurzy[key].value:0;
  })
  data.kurzy = newKurzy;

  //uprava pro komouse
  if(id === "Comunista_CZ"){
    data["hodnost_order"] = 99;
  }

  const memberRef = doc(db, 'clenove', id);
  setDoc(memberRef, data, { merge: true });
}

export default function UserEdit(props:any) {
  const [step,setStep] = [props.step, props.setStep];
  const [error, setError] = useState('');
  const [formSent, setFormSent] = useState(false);
  const [selected, setSelected] = useState(''); //vybrany clen
  

  //krok 1 nacist uzivatele
  const [membersCall, membersState] = useSafeAsync<any,any>(LoadMembers);
  //krok 2 nacist 1 uzivatele
  const [memberCall, memberState] = useSafeAsync<any,any>(LoadMember);
  const [rankCall, rankState] = useSafeAsync<any,any>(LoadRanks);
  const [ribbonsCall, ribbonsState] = useSafeAsync<any,any>(LoadRibbons);
  const [medalsCall, medalsState] = useSafeAsync<any,any>(LoadMedals);
  const [badgesCall, badgesState] = useSafeAsync<any,any>(LoadBadges);
  const [citationsCall, citationsState] = useSafeAsync<any,any>(LoadCitations);

  useEffect(()=>{
    if (selected == "") membersCall({})
    else {
      memberCall({id:selected});
      rankCall({});
      ribbonsCall({});
      medalsCall({});
      badgesCall({});
      citationsCall({});
    }
  },[selected])

  function OrderToRank(order:string) {
    if (rankState.value) {
      const ret = Object.keys(rankState.value).map((e:any)=>{
        const one = rankState.value[e as keyof typeof rankState.value];
        if (one.order == order) {
          return e;
        }
      }).filter(n => n)
      if (ret) return ret[0]
    }
    return "PVT1";
  }

  if (formSent) {
    setTimeout(function(){ window.location.reload(); }, 5000);
    return(<>
    <Typography variant="h4">Formulář odeslán</Typography>
    </>)
  }

  if (selected == "") {
    return(<>
    {(error !== '') && <Typography>{error}</Typography>}
      <LoadingGuard state={membersState} >
        {membersState.value &&
        <Box p={2}>
          <FormContainer
            defaultValues={{}}
          >
            <SelectElement
              label="Člen"
              name="clen"
              fullWidth={true}
              options={membersState.value}
              onChange={setSelected}
            />
          </FormContainer>
        </Box>
        }
      </LoadingGuard>
    </>)
  }else{
    return(<>
      {(error !== '') && <Typography>{error}</Typography>}
      <LoadingGuard state={memberState} >
          {memberState.value && rankState.value && medalsState.value && ribbonsState.value && badgesState.value && citationsState.value &&
          <>
          <Typography variant="h3" m={2}>{selected}</Typography>
          <FormContainer
            defaultValues={getDefaultValues(memberState.value)}
            onSuccess={(data)=>{SaveForm(data,selected);setFormSent(true)}}
          >
            <Stack direction={"column"} spacing={2} p={3}>
            <RankSelect ranks={rankState.value} def={OrderToRank(memberState.value.hodnost_order)}/>
            
            <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Člen od" name="clen_od" /></DateFnsProvider>

            <KurzyFormGroup />

            <RibbonsFormGroup ribbons={medalsState.value} name={"medals"} label={"Medaile"}/>    
            <RibbonsFormGroup ribbons={ribbonsState.value} name={"ribbons"} label={"Ribbony"}/>    
            <RibbonsFormGroup ribbons={badgesState.value} name={"badges"} label={"Badge"}/>    
            <RibbonsFormGroup ribbons={citationsState.value} name={"citations"} label={"Citace"}/>    

            <SocialsFormGroup />     

            <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Uložit změny!</LoaderButton></Box>
            </Stack>
          </FormContainer>
          </>
          }
      </LoadingGuard>
    </>)
  }
  
}

function KurzyFormGroup() {
  const theme = useTheme();
  const form = useFormContext();
  const control = form.control;
  const register = form.register;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "kurzy", // unique name for your Field Array
  });

  function ChangeSelectValue(e:any) {
    form.setValue(e.target.name,e.target.value);
  }

  return(<>
    <Typography variant="h5" pb={1} pt={5}>Kurzy</Typography>
    <Grid container spacing={1}>
      {fields.map((field:any, index) => {
        return (
          <Grid item key={index+"-"+field.id} xs={3}>
            <FormControl fullWidth>
              <InputLabel id={field.name+"-label"} sx={{px:1,background:theme.palette.background.default}} >{field.name}</InputLabel>
              <Controller
                name={`kurzy.${index}.value`}
                control={control}
                defaultValue={field.value}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId={field.name+"-label"}
                    onChange={(e) => {
                      field.onChange(e);
                      ChangeSelectValue(e);
                    }}
                    sx={{mb:1}}
                  >
                    <MenuItem value={0}>------</MenuItem>
                    <MenuItem value={1}>Splnil</MenuItem>
                    <MenuItem value={2}>Instruktor</MenuItem>
                    <MenuItem value={3}>Neudělal zkoušku</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  </>)
}

function RibbonsFormGroup({ribbons, name, label}:{ribbons:any, name:string, label:string}) {
  const form = useFormContext();
  const control = form.control;
  const register = form.register;
  const [value, setValue] = useState('default');

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: name, // unique name for your Field Array
  });

  function handleChange(e:SelectChangeEvent) {
    e.preventDefault();
    if (e.target.value != "" && e.target.value != "default") {
      append({name:e.target.value}); 
    }
    setValue("default");
  }

  return(<>
    <Typography variant="h5" pb={1} pt={5}>{label}</Typography>
    <Grid container spacing={1}>
      {fields.map((field:any, index) => {
        return (
          <Grid item key={field.id} xs={3}>
            <TextField
              {...register(`${name}.${index}.name` as const)}
              fullWidth={true}
              InputProps={{
                endAdornment:(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="remove"
                    onClick={() => remove(index)}
                    onMouseDown={(e)=>{e.preventDefault()}}
                    edge="end"
                  >
                    <Close color="error" />
                  </IconButton>
                </InputAdornment>
                )
              }}
            />
          </Grid>
        );
      })}
    </Grid>
    
    {//<Box><Button variant="outlined"  onClick={()=>{append({name:""})}}><Add /> Odznak</Button></Box>
    }
      <Select
        label={label}
        value={value}
        onChange={handleChange}
      >
        <MenuItem value="default" key="default">Vyber ribbon</MenuItem>
        {ribbons.map((e:any)=>{
          return <MenuItem value={e} key={e}>{e}</MenuItem>
        })}
      </Select>
  </>)
}

function SocialsFormGroup() {
  const form = useFormContext();
  const control = form.control;
  const register = form.register;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "social", // unique name for your Field Array
  });


  return(<>
    <Typography variant="h5" pb={1} pt={5}>Sociální sítě</Typography>
    <Grid container spacing={1}>
      {fields.map((field:any, index) => {
        return (
          <Grid item key={field.id} xs={12}>
            <Stack direction={"row"} spacing={1}>
            <TextField
              {...register(`social.${index}.name`)}
              label={"Nazev"}
              InputProps={{
                endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="remove"
                    onClick={() => remove(index)}
                    onMouseDown={(e)=>{e.preventDefault()}}
                    edge="end"
                  >
                    <Close color="error" />
                  </IconButton>
                </InputAdornment>
              }}
            />
            <TextField
              {...register(`social.${index}.value`)}
              label={"URL"}
              fullWidth
            />
            </Stack>
          </Grid>
        );
      })}
    </Grid>
    
    <Box><Button variant="outlined"  onClick={()=>{append({name:"", value:""})}}><Add /> Social</Button></Box>
  </>)
}

function RankSelect({ranks,def='PVT1'}:{ranks:Object|Array<any>,def:string}) {
  const theme = useTheme();
  const { setValue } = useFormContext();
  
  return(<>
  <TextFieldElement type="hidden" name="hodnost" style={{display:"none"}} />
  <TextFieldElement type="hidden" name="hodnost_order" style={{display:"none"}} />
  <FormControl>
  <InputLabel id="tmp-hodnost-label" sx={{px:1,background:theme.palette.background.default}}>Hodnost</InputLabel>
  <Select
    labelId="tmp-hodnost-label"
    name="tmp-hodnost"
    defaultValue={def}
    onChange={(e)=>{
      const id = e.target.value as keyof typeof ranks;
      const one = ranks[id];
      setValue('hodnost', one.name);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue('hodnost_order', one.order);
    }}
  >
    {Object.keys(ranks).map((e:any)=>{
      const one = ranks[e as keyof typeof ranks];
      return <MenuItem value={e} key={e}>{one.name}</MenuItem>
    })}
  </Select>
  </FormControl>
  </>)
}