export const defaultRangersSlots = {
  MISFIT:{
    'PHQ-1':{kit:'PCO',clen:'',povoleno:true},
    'PHQ-2':{kit:'RTO',clen:'',povoleno:true},
    'PHQ-3':{kit:'JTAC',clen:'',povoleno:true},
    'PHQ-4':{kit:'MED',clen:'',povoleno:true},
    'A-A-1':{kit:'SQL',clen:'',povoleno:true},
    'A-1-1':{kit:'FTL',clen:'',povoleno:true},
    'A-1-2':{kit:'AR',clen:'',povoleno:true},
    'A-1-3':{kit:'RGL',clen:'',povoleno:true},
    'A-1-4':{kit:'RFL',clen:'',povoleno:true},
    'A-2-1':{kit:'FTL',clen:'',povoleno:true},
    'A-2-2':{kit:'AR',clen:'',povoleno:true},
    'A-2-3':{kit:'RGL',clen:'',povoleno:true},
    'A-2-4':{kit:'ARFR',clen:'',povoleno:true},
    'B-A-1':{kit:'SQL',clen:'',povoleno:true},
    'B-1-1':{kit:'FTL',clen:'',povoleno:true},
    'B-1-2':{kit:'AR',clen:'',povoleno:true},
    'B-1-3':{kit:'RGL',clen:'',povoleno:true},
    'B-1-4':{kit:'RFL',clen:'',povoleno:true},
    'B-2-1':{kit:'FTL',clen:'',povoleno:true},
    'B-2-2':{kit:'RGL',clen:'',povoleno:true},
    'B-2-3':{kit:'ARFR',clen:'',povoleno:true},
    'B-2-4':{kit:'RFL',clen:'',povoleno:true},
    'C-A-1':{kit:'SQL',clen:'',povoleno:true},
    'C-1-1':{kit:'FTL',clen:'',povoleno:true},
    'C-1-2':{kit:'AR',clen:'',povoleno:true},
    'C-1-3':{kit:'RGL',clen:'',povoleno:true},
    'C-1-4':{kit:'RFL',clen:'',povoleno:true},
    'C-2-1':{kit:'FTL',clen:'',povoleno:true},
    'C-2-2':{kit:'AR',clen:'',povoleno:true},
    'C-2-3':{kit:'RGL',clen:'',povoleno:true},
    'C-2-4':{kit:'ARFR',clen:'',povoleno:true},
    'W-A-1':{kit:'SQL',clen:'',povoleno:true},
    'W-1-1':{kit:'FTL/ASST',clen:'',povoleno:true},
    'W-1-2':{kit:'MMG',clen:'',povoleno:true},
    'W-2-1':{kit:'FTL/ASST',clen:'',povoleno:true},
    'W-2-2':{kit:'MMG',clen:'',povoleno:true},
    'S-A-1':{kit:'SQL/SPT',clen:'',povoleno:true},
    'S-A-2':{kit:'SNP',clen:'',povoleno:true},
    'E-A-1':{kit:'SQL/EOD',clen:'',povoleno:true},
    'E-A-2':{kit:'EOD',clen:'',povoleno:true},
    'H-A-1':{kit:'SQL/ASST',clen:'',povoleno:true},
    'H-A-2':{kit:'AT',clen:'',povoleno:true},
    'M-A-1':{kit:'SQL',clen:'',povoleno:true},
    'M-1-1':{kit:'FTL/ASST',clen:'',povoleno:true},
    'M-1-2':{kit:'IFR',clen:'',povoleno:true},
    'M-2-1':{kit:'FTL/ASST',clen:'',povoleno:true},
    'M-2-2':{kit:'IFR',clen:'',povoleno:true},
  },
  "SKYFALL":{
    '1-1':{kit:'SQL/JTAC',clen:'',povoleno:true},
    '1-2':{kit:'RTO/JTAC',clen:'',povoleno:true},
    '1-3':{kit:'UAV/JTAC',clen:'',povoleno:true},
    '1-4':{kit:'CLS/JTAC',clen:'',povoleno:true},
  },
  "STALKER 1-1":{
    'PHQ-1':{kit:'SQL/PLT-RW',clen:'',povoleno:true},
    'PHQ-2':{kit:'PLT-RW',clen:'',povoleno:true},
  },
  "STALKER 1-2":{
    'A-A-1':{kit:'PLT-RW',clen:'',povoleno:true},
    'A-A-2':{kit:'PLT-RW',clen:'',povoleno:true},
  },
  "STALKER 1-3":{
    'B-A-1':{kit:'PLT-RW',clen:'',povoleno:true},
    'B-A-2':{kit:'PLT-RW',clen:'',povoleno:true},
  },
  "HORNET 1-1":{
    '1-1':{kit:'SQL/PLT-FW',clen:'',povoleno:true},
    '1-2':{kit:'PLT-FW',clen:'',povoleno:true},
    '1-3':{kit:'PLT-FW',clen:'',povoleno:true},
    '1-4':{kit:'PLT-FW',clen:'',povoleno:true},
  },
  MYSTIC:{
    'PHQ-1':{kit:'UAV',clen:'',povoleno:true},
  },
  BROADWAY:{
    'ZEUS-1':{kit:'Zeus',clen:'',povoleno:true},
    'ZEUS-2':{kit:'Zeus',clen:'',povoleno:true},
    'ZEUS-3':{kit:'Zeus',clen:'',povoleno:true},
    'ZEUS-4':{kit:'Zeus',clen:'',povoleno:true},
    'ZEUS-5':{kit:'Zeus',clen:'',povoleno:true},
  },
  NAHRADNICI:{
    'N-1':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-2':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-3':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-4':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-5':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-6':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-7':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-8':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-9':{kit:'Nahradnik',clen:"",povoleno:true},
    'N-10':{kit:'Nahradnik',clen:"",povoleno:true},
  }
}

