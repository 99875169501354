import { Stack, Typography, Switch, Autocomplete, Box, TextField } from "@mui/material";
import { getStorage, ref, listAll } from "firebase/storage";
import { useState } from "react";
import { useFormContext, TextFieldElement, Controller, Control, FieldValues } from "react-hook-form-mui";
import { AutocompleteOptions, AsyncAutocomplete } from "./asyncAutocomplete";

export function GearFormElement() {
  const { control } = useFormContext();
  const [checked,setChecked] = useState(false);

  const rangerGear = [
    { name:"Ranger 2003-2005", url:"https://docs.google.com/document/d/1kyxgOha4b9RWJ1XHaqaFO3k60MIMhqblD6-xQDQHm0Q" },
    { name:"Ranger 2005-2009", url:"https://docs.google.com/document/d/14y8dBRk95ehOifL1mnutHt1-f4li1PxPV1w-TSRUDLY" },
    { name:"Ranger 2009-2013", url:"https://docs.google.com/document/d/1NrL-p5gkHQhcgFubCi85XvQtnPEJlsQrcr1s87KZat0" },
    { name:"Ranger 2013-současnost", url:"https://docs.google.com/document/d/1fWMW24od5kw_OeFdV7ZdEYr-cIsvzPyHi5MeXq9Sh8w" },
  ]

  function GearAutocomplete() {
    return <Controller
      name="gear"
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={rangerGear}
          autoHighlight
          value={field.value || null} 
          getOptionLabel={(option) => (typeof option === "string")?option:option.url}
          renderOption={(props, option) => (
            <Box component="li" {...props}>{option.name}</Box>
          )}
          freeSolo={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Odkaz na Gear"
              autoComplete="on"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password' // disable autocomplete and autofill
              }}
            />
          )}
          onChange={(_, data) => {
            if (typeof data === 'string' || data instanceof String) {
              field.onChange(data);
            } else if (data && data.url) {
              field.onChange(data.url);
            } else {
              field.onChange('');
            }
          }}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === 'input') field.onChange(newInputValue); //Pokud bude neco vepisovat
          }}
        />
      )}
    />
  }

  return <GearAutocomplete />
}

export function ImagesFormElement(props:any) {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const loading = open && options.length === 0;


  async function LoadImages() {
    const storage = getStorage();
    const zeusRef = ref(storage, '/zeus/');
    var names:Array<AutocompleteOptions> = [];

    await listAll(zeusRef).then((res) => {      
      res.items.forEach((itemRef) => {
        names.push({label:itemRef.name, value:itemRef.name})
      });      
    })
    return names
  }

  
  return (<AsyncAutocomplete control={control} inputName="image" inputLabel="Obrázek mise" asyncFunc={LoadImages}/>)
}