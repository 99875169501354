import { Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../context/firebaseContext";
import { User as FirebaseUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AccessibleForward, Cloud, FileCopy, FolderCopy, Notifications, Person, SkipNext, SkipPrevious } from "@mui/icons-material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-private";
import { UserContext } from "../context/userContext";
import Header from "../header";


async function CheckAdmin(id:string|undefined,set:Function) {
  if (typeof id === "undefined") return false;

  const adminColl = doc(db,'admins',id)
  const adminSnap = await getDoc(adminColl);

  set(adminSnap.exists());
  if (adminSnap.exists()) {
    const data = adminSnap.data() 
    return (data.level)? data.level : 0;
  }
  return 0;
}

async function CheckUserName(uid:string|undefined,set:Function) {
  if (typeof uid === "undefined") return null;

  const userColl = doc(db,'users',uid);
  const userSnap = await getDoc(userColl);

  if (userSnap.exists()) {
    set(userSnap.data().clen)
    return userSnap.data().clen; //ziskani id prihlaseneho clena
  }
  return null;
}



export default function AdminLayout(props:any) {
  const [user,setUser] = useState<FirebaseUser|null>(null)
  const [userCalled, setUserCalled] = useState<boolean>(false);
  const [userName, setUserName] = useState<string|null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [adminLevel, setAdminLevel] = useState<Number>(0);
  const {auth} = useContext(FirebaseContext);
  const Navigate = useNavigate();

  
  const isDesktop = useMediaQuery('(min-width:1000px)');
  const [expanded, setExpanded] = useState<boolean>(isDesktop);
  const cur = props.cur;
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(_user => {
      if(_user?.isAnonymous){setUserCalled(true); setUser(_user); return null;}
      CheckAdmin(_user?.uid,setIsAdmin).then((level:Number)=>{
        CheckUserName(_user?.uid,setUserName).then(()=>{
          setUserCalled(true);
          setAdminLevel(level);
          setUser(_user);
        })
      })
    });
    return () => unsubscribe();
  }, []);

  /*
  useEffect(()=>{
    if (userCalled && isAdmin) {
    }
  },[userCalled])*/
  

  if (!userCalled) {
    return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100vw'} height={'100vh'}><CircularProgress /></Box>
  }

  //ocharna proti anon users a neprihlasenym
  if ((userCalled && !isAdmin) || (userCalled && adminLevel == 0)) {
    Navigate("/");
    return <></>;
  }


  if (userCalled && adminLevel == 2) {
    return(
      <UserContext.Provider value={{user,setUser, userCalled, isAdmin, userName, adminLevel}}>
        <Header title={(props.title)?`${props.title} - 44. Ravens Regiment`:null} description={props.description}/>
        <Stack direction={'row'}>
          <Box minHeight={"100vh"} maxWidth={(expanded)?"450px":"75px"} component={Paper}>
            <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} pr={2}>
              <IconButton aria-label="show/hide" edge="end" onClick={()=>setExpanded(!expanded)}>{(expanded)?<SkipPrevious />:<SkipNext />}</IconButton>
            </Box>
            <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={()=>Navigate("/admin")}>
                    <ListItemIcon>
                      <img src="/logo512.png" height={50} />
                    </ListItemIcon>
                    {(expanded)?<ListItemText primary="44. Ravens" />:<></>}
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton onClick={()=>Navigate("/pro-cleny")}>
                    <ListItemIcon><AccessibleForward /></ListItemIcon>
                    {(expanded)?<ListItemText primary="Pro členy" />:<></>}
                  </ListItemButton>
                </ListItem>

                <Divider />
                <ListItem >
                    <ListItemIcon onClick={()=>Navigate("/admin/l/deployments")}>
                      <FolderCopy />
                    </ListItemIcon>
                    {(expanded)?<ListItemText primary="Deploymenty" />:<></>}
                </ListItem>
                <Divider />
                {(expanded)?<ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/d/new")}>
                    <ListItemText primary="Přidat nový" />
                  </ListItemButton>
                </ListItem>
                :<></>}
                {(expanded)?<><ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/d/dedit")}>
                    <ListItemText primary="Upravit depl." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/d/add")}>
                    <ListItemText primary="Přidat misi" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/d/edit")}>
                    <ListItemText primary="Upravit misi" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/m/end")}>
                    <ListItemText primary="Zapsat docházku" />
                  </ListItemButton>
                </ListItem>
                </>:<></>}

                <Divider />
                <ListItem >
                    <ListItemIcon onClick={()=>Navigate("/admin/l/missions")}>
                      <FileCopy />
                    </ListItemIcon>
                    {(expanded)?<ListItemText primary="Mise" />:<></>}
                </ListItem>
                <Divider />
                {(expanded)?<>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/m/new")}>
                    <ListItemText primary="Přidat" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/m/edit")}>
                    <ListItemText primary="Upravit" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/m/end")}>
                    <ListItemText primary="Zapsat docházku" />
                  </ListItemButton>
                </ListItem>
                </>:<></>}

                <Divider />
                <ListItem >
                    <ListItemIcon>
                      <Cloud />
                    </ListItemIcon>
                    {(expanded)?<ListItemText primary="Soubory" />:<></>}
                </ListItem>
                <Divider />
                {(expanded)?<>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>Navigate("/admin/files/upload")}>
                    <ListItemText primary="Nahrát" />
                  </ListItemButton>
                </ListItem>
                </>:<></>}
              </List>
          </Box>
          <Box width={"100%"}>
            {props.children}
          </Box>
        </Stack>
      </UserContext.Provider>
    );
  }


  

  return(
  <>
  <UserContext.Provider value={{user,setUser, userCalled, isAdmin, userName, adminLevel}}>
    <Header title={(props.title)?`${props.title} - 44. Ravens Regiment`:null} description={props.description}/>
    <Stack direction={'row'}>
      <Box minHeight={"100vh"} maxWidth={(expanded)?"450px":"75px"} component={Paper}>
        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} pr={2}>
          <IconButton aria-label="show/hide" edge="end" onClick={()=>setExpanded(!expanded)}>{(expanded)?<SkipPrevious />:<SkipNext />}</IconButton>
        </Box>
        <List>
            <ListItem disablePadding>
              <ListItemButton onClick={()=>Navigate("/admin")}>
                <ListItemIcon>
                  <img src="/logo512.png" height={50} />
                </ListItemIcon>
                {(expanded)?<ListItemText primary="44. Ravens" />:<></>}
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={()=>Navigate("/pro-cleny")}>
                <ListItemIcon><AccessibleForward /></ListItemIcon>
                {(expanded)?<ListItemText primary="Pro členy" />:<></>}
              </ListItemButton>
            </ListItem>

            <Divider />
            <ListItem >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                {(expanded)?<ListItemText primary="Členové" />:<></>}
            </ListItem>
            <Divider />
            {(expanded)?<><ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/c/new")}>
                <ListItemText primary="Přidat nového" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/c/edit")}>
                <ListItemText primary="Upravit" />
              </ListItemButton>
            </ListItem></>
            :<></>}

            <Divider />
            <ListItem >
                <ListItemIcon onClick={()=>Navigate("/admin/l/deployments")}>
                  <FolderCopy />
                </ListItemIcon>
                {(expanded)?<ListItemText primary="Deploymenty" />:<></>}
            </ListItem>
            <Divider />
            {(expanded)?<ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/d/new")}>
                <ListItemText primary="Přidat nový" />
              </ListItemButton>
            </ListItem>
            :<></>}
            {(expanded)?<><ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/d/dedit")}>
                <ListItemText primary="Upravit depl." />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/d/add")}>
                <ListItemText primary="Přidat misi" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/d/edit")}>
                <ListItemText primary="Upravit misi" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/m/end")}>
                <ListItemText primary="Zapsat docházku" />
              </ListItemButton>
            </ListItem>
            </>:<></>}

            <Divider />
            <ListItem >
                <ListItemIcon onClick={()=>Navigate("/admin/l/missions")}>
                  <FileCopy />
                </ListItemIcon>
                {(expanded)?<ListItemText primary="Mise" />:<></>}
            </ListItem>
            <Divider />
            {(expanded)?<>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/m/new")}>
                <ListItemText primary="Přidat" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/m/edit")}>
                <ListItemText primary="Upravit" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/m/end")}>
                <ListItemText primary="Zapsat docházku" />
              </ListItemButton>
            </ListItem>
            </>:<></>}

            <Divider />
            <ListItem >
                <ListItemIcon>
                  <Cloud />
                </ListItemIcon>
                {(expanded)?<ListItemText primary="Soubory" />:<></>}
            </ListItem>
            <Divider />
            {(expanded)?<>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/files/upload")}>
                <ListItemText primary="Nahrát" />
              </ListItemButton>
            </ListItem>
            </>:<></>}

            <Divider />
            <ListItem >
                <ListItemIcon>
                  <Notifications />
                </ListItemIcon>
                {(expanded)?<ListItemText primary="Notifikace" />:<></>}
            </ListItem>
            <Divider />
            {(expanded)?<>
            <ListItem disablePadding >
              <ListItemButton onClick={()=>Navigate("/admin/notifications/manual")}>
                <ListItemText primary="Ruční poslání" />
              </ListItemButton>
            </ListItem>
            </>:<></>}

          </List>
      </Box>
      <Box width={"100%"}>
        {props.children}
      </Box>
    </Stack>
  </UserContext.Provider>
  </>
  )
}
