import { Box, CircularProgress, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ref } from "firebase/storage";
import { useState, useEffect } from "react";
import { storage } from "../components/firebase-public";
import { GetImageURL } from "../components/helpers";



function ParralaxBlock(props:any) { 
  const [backImg,setBackImg] = useState('');

  useEffect(()=>{
    GetImageURL(ref(storage,'system/'+props.image+'.png')).then(url => setBackImg(url))
  },[])

  if (props.dim) {
    var bckg = {background:"linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url("+backImg+")"};
  } else {
    var bckg = {background: "url("+backImg+")"};
  }



  if (backImg == '') {
    return(<>
    <Box height={"50vh"}
      display={"flex"} alignItems={"center"} justifyContent={"center"}
    >
      <CircularProgress />
    </Box>
    </>)
  }

  return(<>
  <Box height={"50vh"}
    sx={{
      ...bckg,
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      
    }}
  >
   {props.children}   
  </Box>
  </>);
  
}

function TextBlock(props:any) {
  const theme = useTheme();
  const IsBigMonitor = useMediaQuery('(min-width:'+theme.breakpoints.values['xxl']+'px)');
  const isNotPhone = useMediaQuery('(min-width:600px)');
  
  return (<>
    <Stack direction={"column"} px={(IsBigMonitor)?"15%":((isNotPhone)?"25%":1)} justifyContent={"center"} height={"50vh"}>
      <Typography variant={(IsBigMonitor)?"h2":"h4"} sx={{borderBottom:"2px solid white"}} width={"fit-content"} pr={2} mb={2}>{props.title}</Typography>
      {(props.ownChildren)?<>{props.children}</>:<Typography variant="body2" >{props.children}</Typography>}
      
    </Stack>
  </>)
}



export default function AboutPage() {
    const theme = useTheme();



    return(<>

    <Stack direction={"column"}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} my={1}>
        <Typography variant="h1">Proč právě naši Arma 3 CZ skupinu?</Typography>
      </Box>
      <ParralaxBlock image={"RangersHELO1"}>
        <TextBlock title={"O skupině 44. Ravens Regiment"} ownChildren={true}>
        <Typography variant="body2" >44. Ravens Regiment je skupina založena primárně pro kooperativní hraní ArmA 3 s inspirací u 75th Ranger Regimentu. Aby jsme se naší reálné předloze co nejvíce přiblížili tak máme soukromé módy které nám přidávají kvalitní gear, Rangery inspirovaný ORBAT a z reality přebrané postupy.</Typography>
        <Typography variant="body2" >Avšak si uvědomujeme že stále hrajeme hru a netlačíme realismus před požitek ze hry.</Typography>  
        </TextBlock>
      </ParralaxBlock>
      
      <ParralaxBlock image={"RangersNight2"}>
        <TextBlock title={"A kdo vlastně jsme?"}>
          Jsme skupina hráčů, která se dlouhodobě věnuje simulačním vojenským hrám, někteří z nás již od roku 2013. Nejen že se snažíme zlepšovat v Armě, ale především chceme vybudovat fungující, jednotnou a přátelskou komunitu, kterou spojuje nejen ArmA. V současné době naše komunita stále roste a ty můžeš být její součástí!  
        </TextBlock>
      </ParralaxBlock>
      
      <ParralaxBlock image={"RangersINF1"} dim={true}>
        <TextBlock title={"Jak se k nám přidat?"} ownChildren={true}>
          <Typography variant="body2" mb={1}>Aby jsi mohl rozšířit naše řady tak potřebuješ splňovat následující:</Typography>
          <Typography variant="body2" >- Vlastnit originální kopii ArmA 3 (DLC nepožadujeme, Doporučujeme Apex)</Typography>
          <Typography variant="body2" >- Mít čas se alespoň jednou za měsíc objevit na výcviku nebo na misi (samozřejmě se dá domluvit na vyjímce)</Typography>
          <Typography variant="body2" >- Mít zájem o seberozvoj</Typography>
          <Typography variant="body2" mt={1}>Pokud všechno výše uvedené splňuješ tak ti pouze stačí vyplnit tento <Link href={"/pridat"}>formulář</Link> a kontaktovat nás skrze náš discord. Poté s tebou provedeme úvodní pohovor a zařadíme tě do našich řad. V případě jakýchkoliv dotazů nás neváhej kontaktovat na discordu.</Typography>
          </TextBlock>
      </ParralaxBlock>
      
      <ParralaxBlock image={"RangersINF3"} dim={true}>
        <TextBlock title={"Naše fungování a styl hraní"} ownChildren={true}>
          <Typography variant="body2" >Armu hrajeme formou deploymentů, každý druhý měsíc si naší missionmakeři připraví kampaň (deployment) na kterou se následně vydáme. V době home rotationu (času mezi deploymenty) trénujeme, zaškolujeme nové Rangery a připravujeme se na deployment.</Typography>
          <Typography variant="body2" >Klademe důraz na rovnováhu mezi zábavou a realismem. U nás se naučíte vše co budete pro organizované hraní Army potřebovat, takže můžete být i úplný nováček. Naše tréninky zakládáme na zkušenostech s armou a na postupech které používá reálný Ranger regiment.</Typography>  
        </TextBlock>
      </ParralaxBlock>
      
      <ParralaxBlock image={"RangersINF4"}>
        <TextBlock title={"Jak vlastně tato skupina vznikla?"}>
          Naše komunita byla založena uživateli Comunista_CZ a Murra v květnu roku 2020 za účelem kooperativního hraní ArmA 3 ve stylu semi realismu. Po několika letech hraní jsme se rozhodli náš klan zaměřit na specifickou jednotku a tou jsou právě Američtí Rangers. V současné době klan vede command sekce v čele s Fuzeniksem.
        </TextBlock>
      </ParralaxBlock>

    </Stack>
    </>);    
}