import { Typography, Stack, Box, Button, Autocomplete, TextField, CircularProgress, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormContainer, TextFieldElement, useFormContext } from "react-hook-form-mui";

import { defaultRangersSlots } from "./defaultSlots";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSafeAsync } from "../useSafeAsync";
import LoadingGuard from "../loadingGuard";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-private";

const functions = getFunctions();
const sendToToken = httpsCallable(functions, 'notifications-sendToToken');

async function LoadTokens({}:{}) {
  const tokensColl = collection(db,'messaging');
  const tokensSnap = await getDocs(tokensColl);

  var tokens:any[] = [];
  tokensSnap.forEach((doc)=>{
    var data = doc.data()
    tokens.push({user:doc.id,token:data.token});
  })
  
  return tokens;
}

async function SaveForm(data:any) {

  if (!data.title || data.title === "") return false;
  if (!data.text || data.text === "") return false;
  if (data.tokens.length <= 0) return false;
  
  try {
    const promises = data.tokens.map((token:string) => {
      return sendToToken({token:token, title: data.title, text: data.text, url: (data.url)?data.url:"https://ravens-regiment.com/pro-cleny"});
    })
    const responses = await Promise.all(promises);
    return responses;
  } catch (error) {
    console.error("Error sending notifications", error);
    return false;
  }
}

export default function NotificationsManualPage(props:any) {
  const [step,setStep] = [props.step, props.setStep];
  const [formSent,setFormSent] = useState(false);
  const [formResponse,setFormResponse] = useState<any[]|boolean|null>(null);
  const [tokensCall, tokensState] = useSafeAsync<any,any>(LoadTokens);

  useEffect(()=>{
    tokensCall({});
  },[])
  useEffect(()=>{
    console.log(formSent, formResponse);
  },[formSent, formResponse])


  if (formSent) {
    return(<Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'}><CircularProgress /></Box>)
  }else if(formResponse !== null && typeof formResponse !== "boolean"){
    return(
    <LoadingGuard state={tokensState}>
      { tokensState.value &&
        <Stack direction={"column"} spacing={2}>
          {formResponse.map((response:any, index)=>{
            return <Alert key={index} severity={response.data.status}>{response.data.data}</Alert>
          })}
        </Stack>
      }
    </LoadingGuard>
    )
  }

  return(<>
    <LoadingGuard state={tokensState}>
      { tokensState.value &&
          <FormContainer
          onSuccess={(data)=>{setFormSent(true); SaveForm(data).then((result)=>{setFormResponse(result); setFormSent(false);})}}
          defaultValues={
            {
              tokens: [],
              title: null,
              text: null,
              url: null,
            }
          }
        >
          <Stack direction={"column"} spacing={2} p={3}>
            <TokensAutocomplete options={tokensState.value} />

            <TextFieldElement label="Nadpis" name="title" />
            <TextFieldElement label="Text" name="text" />
            <TextFieldElement label="URL (nepovinné)" name="url" />
          
            <Box sx={{display:"flex",justifyContent:"center"}}><Button type="submit" variant="outlined">Vložit</Button></Box>
          </Stack>
        </FormContainer>
      }
    </LoadingGuard>
    </>)
}

function TokensAutocomplete({options}:{options:[{user:string,token:string}]}) {
  const { control } = useFormContext();
  
  return <Controller
    name="tokens"
    control={control}
    render={({ field }) => (
      <Autocomplete
        {...field}
        options={options}
        multiple={true}
        value={options.filter(option => field.value.includes(option.token))}
        getOptionLabel={(option) => (typeof option === "string")?option:option.user}
        renderOption={(props, option) => (
          <Box component="li" {...props}>{option.user}</Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Členové"
            autoComplete="on"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password' // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(_, data) => {
          if (Array.isArray(data)) {
            const tokens = data.map(item => {
              return item.token || '';
            });
            field.onChange(tokens);
          } else {
            field.onChange([]);
          }
        }}
      />
    )}
  />
}