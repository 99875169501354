import { Button, CircularProgress } from "@mui/material";
import { useCallback, useMemo, useState } from "react";



export default function LoaderButton(props:any) {
  const [loading, setLoading] = useState(false);
  const memo = useMemo(()=>{setLoading(Boolean(props.loading))},[props.loading])
  
  return (
    <Button 
      variant={props.variant ?? "outlined"} 
      startIcon={(loading)?<CircularProgress />:<></>}
      sx={(loading)?{ "& .MuiButton-startIcon": { marginRight: "0px" }}:{}}
      onClick={ ()=>{console.log("YOLO",loading);setLoading(!loading)} }
      type={(props.type)??"button"}

    >
      { (!loading)? props.children :""}
    </Button>
  );
}