import { Typography, Stack, Box, Button, Grid, SxProps, Checkbox, Autocomplete, TextField } from "@mui/material";
import { cs } from "date-fns/locale";
import { createContext, useEffect, useRef, useState } from "react";
import { FormContainer, TextFieldElement, DateTimePickerElement, SelectElement, TextareaAutosizeElement, CheckboxElement, useFormContext, Controller } from "react-hook-form-mui";
import DateFnsProvider from "../DateFnsProvider";
import { Timestamp, collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase-private";
import { ReplaceSpecials } from "../helpers";
import { useSafeAsync } from "../useSafeAsync";
import LoadingGuard from "../loadingGuard";
import { defaultRangersSlots } from "./defaultSlots";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, listAll } from "firebase/storage";
import { AutocompleteOptions, AsyncAutocomplete } from "../asyncAutocomplete";
import { GearFormElement, ImagesFormElement } from "../gearFormElement";
import LoaderButton from "../loaderButton";

const functions = getFunctions();
const sendNotification = httpsCallable(functions, 'notifications-send');





async function SaveForm(data:any) {
  const missionId = data["mission-id"];

  if (!missionId || missionId === "" || !data.deployment) {
    return null;
  }
  
  if (data.datum !== ""){
    data.datum = Timestamp.fromDate(data.datum)
  }else{
    data.datum = Timestamp.fromDate(new Date("01-01-1970"))
  }

  if (data.gear) {
    if (typeof data.gear === "string") {
      data.gear = data.gear;
    } else {
      data.gear = data.gear.url;
    }
  } else {
    data.gear = "";
  }


  Object.keys(data).map((i)=>{
    if(data[i] === undefined) data[i] = "";
    if (typeof data[i] === "object") {
      Object.keys(data[i]).map((j)=>{
        if(data[i][j] === undefined) data[i][j] = "";
        if (typeof data[j] === "object") {
          Object.keys(data[j]).map((k)=>{
            if(data[i][j][k] === undefined) data[i][j][k] = "";
            return null;
          })
        }
        return null;
      })
    }
    return null;
  })
  
  const memberRef = doc(db, 'deploymenty', data.deployment, "mise", missionId);
  return setDoc(memberRef, data, { merge: true }).then(() => {
    const datum = `v ${data.datum.toLocaleString("cs-CZ",{weekday:"long"})} ${data.datum.toLocaleDateString("cs-CZ")} v ${data.datum.toLocaleTimeString("cs-CZ").slice(0,-3)}`;
    return sendNotification({title:"Byla vyhlášena nová deployment mise", text: `Nová deployment mise ${data.nazev} ${datum}`, url: `https://ravens-regiment.com/deployment/${data.deployment}/${data.id}`});
  });
  
}

async function LoadDeployments() {
  const deploymentsColl = collection(db,'deploymenty');
  const deploymentsSnap = await getDocs(deploymentsColl);

  var deployments:any[] = [];
  deploymentsSnap.forEach((doc)=>{
    var data = doc.data()
    deployments.push({id:doc.id,label:data.nazev});
  })
  
  return deployments;
}



export default function DeploymentAdd({step,setStep,id}:{step:number, setStep:Function,id?:string|null}) {
  const [formSent,setFormSent] = useState(false);
  const [deploymentsCall, deploymentsState] = useSafeAsync<any,any>(LoadDeployments);

  useEffect(()=>{
    deploymentsCall({});
  },[])


  if (formSent) {
    return(<>
      <Typography variant="h3">Odesláno</Typography>
    </>)
  }

  const defaultValues={
    sloty: defaultRangersSlots,
    datum: new Date(),
    dokoncena: 'ne',
    deployment: (id)? id : "",
  }

  return(<>
    <LoadingGuard state={deploymentsState}>
      { deploymentsState.value &&
          <FormContainer
          onSuccess={(data)=>{SaveForm(data).then(()=>{setFormSent(true)})}}
          defaultValues={defaultValues}
        >
          <Stack direction={"column"} spacing={2} p={3}>
          <SelectElement
            label="Deployment"
            name="deployment"
            fullWidth={true}
            options={deploymentsState.value}
            
          />
          <SelectElement
            label="Dokončený"
            name="dokoncena"
            fullWidth={true}
            options={[{id:'ano',label:'Ano'},{id:'ne',label:'Ne'}]}
            
          />
          <DynamicMissionName />
          
          <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum konání" name="datum"/></DateFnsProvider>
          <TextFieldElement label="Povinné" name="povinne" value={"OK"}/>
          <TextFieldElement label="Povolené" name="povolene" />
          <TextFieldElement label="Zakázané" name="zakazane" />
          <TextareaAutosizeElement label="Dodatečné" name="dodatecne" />
          
          <GearFormElement />

          <TextFieldElement label="Briefing" name="briefing" />
          <ImagesFormElement/>

          <RangersSlots />

          <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Vložit</LoaderButton></Box>
          </Stack>
        </FormContainer>
      }
    </LoadingGuard>
    </>)
}

function RangersSlots(props:any) {

  return(<>
    <Stack direction={"column"} spacing={1}>
      <SlotGroup platoon={"MISFIT"}>
        <Slot platoon={"MISFIT"} slot={"PHQ-1"} kit={"PCO"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"PHQ-2"} kit={"RTO"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"PHQ-3"} kit={"JTAC"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"PHQ-4"} kit={"MED"} clen={""} /> 
        <Spacer />
        <Slot platoon={"MISFIT"} slot={"A-A-1"} kit={"SQL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-1-1"} kit={"FTL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-1-2"} kit={"AR"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-1-3"} kit={"RGL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-1-4"} kit={"RFL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-2-1"} kit={"FTL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-2-2"} kit={"AR"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-2-3"} kit={"RGL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"A-2-4"} kit={"RFL"} clen={""} />
        <Spacer /> 
        <Slot platoon={"MISFIT"} slot={"B-A-1"} kit={"SQL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-1-1"} kit={"FTL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-1-2"} kit={"AR"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-1-3"} kit={"RGL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-1-4"} kit={"RFL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-2-1"} kit={"FTL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-2-2"} kit={"AR"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-2-3"} kit={"RGL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"B-2-4"} kit={"RFL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-A-1"} kit={"SQL"} clen={""} />
        <Spacer /> 
        <Slot platoon={"MISFIT"} slot={"C-1-1"} kit={"FTL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-1-2"} kit={"AR"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-1-3"} kit={"RGL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-1-4"} kit={"RFL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-2-1"} kit={"FTL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-2-2"} kit={"AR"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-2-3"} kit={"RGL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"C-2-4"} kit={"RFL"} clen={""} />
        <Spacer /> 
        <Slot platoon={"MISFIT"} slot={"W-A-1"} kit={"SQL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"W-1-1"} kit={"FTL/ASST"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"W-1-2"} kit={"MMG"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"W-2-1"} kit={"FTL/ASST"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"W-2-2"} kit={"MMG"} clen={""} />
        <Spacer /> 
        <Slot platoon={"MISFIT"} slot={"S-A-1"} kit={"SQL/SPOT"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"S-A-2"} kit={"SNP"} clen={""} />
        <Spacer /> 
        <Slot platoon={"MISFIT"} slot={"E-A-1"} kit={"SQL/EOD"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"E-A-2"} kit={"EOD"} clen={""} />
        <Spacer /> 
        <Slot platoon={"MISFIT"} slot={"H-A-1"} kit={"SQL/ASST"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"H-A-2"} kit={"AT"} clen={""} />
        <Spacer /> 
        <Slot platoon={"MISFIT"} slot={"M-A-1"} kit={"SQL"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"M-1-1"} kit={"FTL/ASST"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"M-1-2"} kit={"IFR"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"M-2-1"} kit={"FTL/ASST"} clen={""} /> 
        <Slot platoon={"MISFIT"} slot={"M-2-2"} kit={"IFR"} clen={""} />
      </SlotGroup>
      <SlotGroup platoon={"SKYFALL"}>
        <Slot platoon={"SKYFALL"} slot={"1-1"} kit={"SQL/JTAC"} clen={""} /> 
        <Slot platoon={"SKYFALL"} slot={"1-2"} kit={"SQL/JTAC"} clen={""} /> 
        <Slot platoon={"SKYFALL"} slot={"1-3"} kit={"SQL/JTAC"} clen={""} /> 
        <Slot platoon={"SKYFALL"} slot={"1-4"} kit={"SQL/JTAC"} clen={""} />
      </SlotGroup>
      <SlotGroup platoon={"STALKER 1-1"}>
        <Slot platoon={"STALKER 1-1"} slot={"PHQ-1"} kit={"PLT-RW"} clen={""} /> 
        <Slot platoon={"STALKER 1-1"} slot={"PHQ-2"} kit={"PLT-RW"} clen={""} />
      </SlotGroup><SlotGroup platoon={"STALKER 1-2"}> 
        <Slot platoon={"STALKER 1-2"} slot={"A-A-1"} kit={"PLT-RW"} clen={""} /> 
        <Slot platoon={"STALKER 1-2"} slot={"A-A-2"} kit={"PLT-RW"} clen={""} /> 
      </SlotGroup><SlotGroup platoon={"STALKER 1-3"}>
        <Slot platoon={"STALKER 1-3"} slot={"B-A-1"} kit={"PLT-RW"} clen={""} /> 
        <Slot platoon={"STALKER 1-3"} slot={"B-A-2"} kit={"PLT-RW"} clen={""} />
      </SlotGroup> 
      <SlotGroup platoon={"HORNET 1-1"}> 
        <Slot platoon={"HORNET 1-1"} slot={"1-1"} kit={"SQL/PLT-FW"} clen={""} /> 
        <Slot platoon={"HORNET 1-1"} slot={"1-2"} kit={"PLT-FW"} clen={""} />
        <Slot platoon={"HORNET 1-1"} slot={"1-3"} kit={"PLT-FW"} clen={""} /> 
        <Slot platoon={"HORNET 1-1"} slot={"1-4"} kit={"PLT-FW"} clen={""} />
      </SlotGroup>
      <SlotGroup platoon={"MYSTIC"}>
        <Slot platoon={"MYSTIC"} slot={"PHQ-1"} kit={"UAV"} clen={""} /> 
      </SlotGroup>
      <SlotGroup platoon={"BROADWAY"}> 
        <Slot platoon={"BROADWAY"} slot={"ZEUS-1"} kit={"Zeus"} clen={""} /> 
        <Slot platoon={"BROADWAY"} slot={"ZEUS-2"} kit={"Zeus"} clen={""} /> 
        <Slot platoon={"BROADWAY"} slot={"ZEUS-3"} kit={"Zeus"} clen={""} /> 
        <Slot platoon={"BROADWAY"} slot={"ZEUS-4"} kit={"Zeus"} clen={""} /> 
        <Slot platoon={"BROADWAY"} slot={"ZEUS-5"} kit={"Zeus"} clen={""} />
      </SlotGroup> 
      <SlotGroup platoon={"NAHRADNICI"}>
        <Slot platoon={"NAHRADNICI"} slot={"N-1"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-2"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-3"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-4"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-5"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-6"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-7"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-8"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-9"} kit={"Nahradnik"} clen={""} /> 
        <Slot platoon={"NAHRADNICI"} slot={"N-10"} kit={"Nahradnik"} clen={""} />
      </SlotGroup>
    </Stack>
  </>)
}

function Slot({platoon = "",slot = "",kit = "",clen = ""}:{platoon:string,slot:string,kit:string,clen:string}) {

  
  return(<> 
    <Grid item xs={2}>
      <Typography variant="body2" fontSize={'1.75rem'} >{slot}</Typography>
    </Grid>
    <Grid item xs={4.5}>
      <TextFieldElement label={kit} name={`sloty[${platoon}][${slot}][kit]`} sx={{marginRight:1}} size="small" fullWidth />
    </Grid>
    <Grid item xs={4.5}>
      <TextFieldElement label={"Člen"} name={`sloty[${platoon}][${slot}][clen]`} size="small" fullWidth />
    </Grid>
    <Grid item xs={1} justifyContent={"center"} alignItems={"center"}>
      <CheckboxElement name={`sloty[${platoon}][${slot}][povoleno]`} size="small" />
    </Grid>
  </>);
}

function SlotGroup(props:any) {
  const [allChecked, setAllChecked] = useState(true);
  const firstUpdate = useRef(true);
  const groupContext= createContext({ platoon: props.platoon, setAllChecked: setAllChecked });
  const form = useFormContext();

  useEffect(()=>{
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const val = form.getValues(`sloty[${props.platoon}]`)
    Object.keys(val).map((key:string)=>{
      form.setValue(`sloty[${props.platoon}][${key}][povoleno]`,allChecked);
      return null;
    });
  },[allChecked])


  var styles:SxProps ={}
  if (props.platoon.match(/STALKER/)) {
    styles={backgroundColor:'#592525'}
  } else if (props.platoon.match(/SKYFALL/)) {
    styles={backgroundColor:'#595425'}
  } else if (props.platoon.match(/HORNET/)) {
    styles={backgroundColor:'#1c3879'}
  } else if (props.platoon.match(/BROADWAY/)) {
    styles={backgroundColor:'#41454d'}
  } else if (props.platoon.match(/MYSTIC/)) {
    styles={backgroundColor:'#03550c'}
  } else if (props.platoon.match(/NAHRADNICI/)) {
    styles={backgroundColor:'#03550c'}
  }

  return(
  <Grid container direction={"row"} alignItems={"center"} spacing={1} p={1} sx={styles}>
    <Grid item xs={11}>
      <Typography variant="h4" fontSize={'0.75rem'} width={'100%'} textAlign={"center"} >{props.platoon}</Typography>
    </Grid>
    <Grid item xs={1}>
      <Checkbox defaultChecked={allChecked} value={allChecked} onChange={()=>setAllChecked(!allChecked)}/>  
    </Grid>

    <groupContext.Provider value={props.platoon}>
      {props.children}
    </groupContext.Provider>
  </Grid>
  )
}

function DynamicMissionName() {
  const form = useFormContext();
  
  return(<>
    <TextFieldElement label="Název mise" name="nazev" onChange={(t)=>{
        var target = t.target as HTMLInputElement;
        form.setValue("mission-id",ReplaceSpecials(target.value));
      }}
    />
    <TextFieldElement label="ID mise" name="mission-id" InputProps={{readOnly: true,}}/>
  </>)
}

function Spacer() {
  return<>
  <Grid item xs={12}>
    <Box height={"2px"} sx={{background:'white'}}/>
  </Grid>
  </>
}

