import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Layout from './layout';
import UserLayout from './components/user-zone/layout';
import { createTheme, CssBaseline, PaletteColorOptions, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './error-page';
import HomePage from './routes/home';
import MembersPage from './routes/members';
import MemberPage from './routes/member';
import JoinPage from './routes/join';
import MemberSectionHomePage from './routes/memberSectionHome';
import AdminPage from './routes/admin';
import MissionsPage from './routes/missions';
import MissionOnePage from './routes/mission';
import AdminCommandsPage from './routes/adminCommands';
import LoginPage from './routes/login';
import AboutPage from './routes/about';
import './index.css';
import DeploymentsPage from './routes/deployments';
import AkcePage from './routes/calendar';
import SearchKurzyPage from './routes/searchKurzy';
import SoloMissionsPage from "./routes/soloMissions";
import Redirect from './routes/redirect';
import AdminLayout from './components/admin/layout';
import UserProfilePage from './routes/profile';

declare module '@mui/material/styles' {
  interface CustomPalette {
    black?: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
  }
}
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    big: true;
    mega: true;
    ultra: true;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// ---------------------- THEME ---------------------------------------------
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor:string) => augmentColor({ color: { main: mainColor } });



var theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
      big: 1921,
      mega: 2560,
      ultra: 3200,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#385F85',
    },
    black:createColor("#000"),
  },
  typography:{
    fontFamily: `"Montserrat","Open Sans","Roboto","Helvetica","Arial",sans-serif,'Shojumaru','Contrail One'`,
    h1:{
      fontFamily: 'Purista',
      fontSize:'5.5rem',
    },
    h2:{
      fontFamily: 'Purista',
      fontSize:'5rem',
    },
    h3:{
      fontFamily: 'Purista',
      fontSize:'4rem',
    },
    h4:{
      fontFamily: 'Purista',
      fontSize:'3rem',
    },
    h5:{
      fontFamily: 'Purista',
      fontSize:'2.5rem',
    },
    h6:{
      fontFamily: 'Purista',
      fontSize:'2rem',
    },
    body1:{
      fontSize:'1.5rem',
    },
    body2:{
      fontSize:'0.9rem',
    }
  },
});

//uprava pro vetsi monitory
theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.up("xxl")]: {
    fontSize: "1.5rem"
  }
}
theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.up("xxl")]: {
    fontSize: "2rem"
  }
}

theme = responsiveFontSizes(theme,{ breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'big', 'mega', 'ultra'], factor: 5 });

// ------------------------------------- ROUTER ------------------------------------

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout title="Domů" description={"Jsme Česká skupina pro co-op hraní Arma 3 (Arma 3 CZ/SK) s inspirací u 75th Ranger Regimentu. Netlačíme realismus před požitek ze hry. Přidej se k nám!"}><HomePage /></Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/clenove",
    element: <Layout title="Členové" description={"Stránka obsahující všechny členy naší komunity a jejich hodnosti a úspěchy. Můžeš se tady ukázat taky!"}><MembersPage /></Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/clen/:member",
    element: <Layout><MemberPage /></Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/pridat",
    element: <Layout title="Připoj se" description={"Pokud máš zájem se k nám přidat vyplň formulář na této stránce a přidej se na náš discord. Nezáleží jestli jsi nováček nebo vracející se hráč."}><JoinPage /></Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/login",
    element: <Layout title="Přihlášení"><LoginPage /></Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/o-nas",
    element: <Layout title="O nás" description={"Jsme Česká skupina založena pro kooperativní hraní Arma 3 (Arma 3 CZ/SK) s inspirací u 75th Ranger Regimentu. Netlačíme realismus před požitek ze hry."}><AboutPage /></Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/pro-cleny",
    element: <UserLayout><MemberSectionHomePage /></UserLayout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <UserLayout cur="profile"><UserProfilePage /></UserLayout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/akce",
    element: <UserLayout cur="akce"><AkcePage /></UserLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/mise",
    element: <UserLayout cur="mise"><SoloMissionsPage /></UserLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/mise/:missionId",
    element: <UserLayout cur="mise"><MissionOnePage /></UserLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/deployment",
    element: <UserLayout cur="deployment"><DeploymentsPage /></UserLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/deployment/:deploymentId",
    element: <UserLayout cur="deployment"><MissionsPage /></UserLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/deployment/:deploymentId/:missionId",
    element: <UserLayout cur="deployment"><MissionOnePage /></UserLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/pro-cleny/hledani-kurzu/:kurz",
    element: <UserLayout><SearchKurzyPage /></UserLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/admin",
    element: <AdminLayout><AdminPage /></AdminLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/admin/:type/:command",
    element: <AdminLayout><AdminCommandsPage /></AdminLayout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/discord",
    element: <Redirect url="https://discord.gg/QZtJaMTf5C" />,
    errorElement: <ErrorPage />
  },
]);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
      <RouterProvider  router={router} />
  </ThemeProvider>
);

/* Service worker pro PWA (app) */
window.addEventListener("load", () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("service-worker.js").then(function(registration) {
      console.log('Service Worker Registered', registration);
    })
    .catch(function(err) {
      console.log('Service Worker registration failed', err);
    });;
    navigator.serviceWorker.register("firebase-messaging-sw.js").then(function(registration) {
      console.log('Service Worker Registered', registration);
    })
    .catch(function(err) {
      console.log('Service Worker registration failed', err);
    });;
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();