export const firebaseConfig = {
  apiKey: "AIzaSyA8L8H2EcO55Ykr3bccGQre3APiQT_KLvY",
  authDomain: "ravens-regiment.firebaseapp.com",
  projectId: "ravens-regiment",
  storageBucket: "ravens-regiment.appspot.com",
  messagingSenderId: "19542372257",
  appId: "1:19542372257:web:e87a1d20acdbb54b5aa5e0",
  measurementId: "G-D518NWGFMZ"
};

