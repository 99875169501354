import { Timestamp, collection, getDocs, query } from "firebase/firestore";
import { db } from "../components/firebase-public";
import { UserContext } from "../components/context/userContext";
import { useContext, useEffect } from "react";
import LoadingGuard from "../components/loadingGuard";
import { useSafeAsync } from "../components/useSafeAsync";
import { Grid, IconButton, Link, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { FileCopy } from "@mui/icons-material";


async function GetMissions({deploymentId}:{deploymentId:string}) {
  const date = new Date();
  //date.setHours(date.getHours() - 4); // den nazpet
  const missionsColl = collection(db, 'deploymenty', deploymentId, 'mise');

  const missionsSnapshot = await getDocs( query(missionsColl));
  if (!missionsSnapshot.empty) {
    return missionsSnapshot.docs.map((doc) => {return {id:doc.id,...doc.data()}} );
  }

  return [];
}

export default function MissionsPage(props:any) {
  const [missionsCall, missionsState] = useSafeAsync<any,any>(GetMissions);
  const userObject = useContext(UserContext);
  const { deploymentId } = useParams()
  const isNotPhone = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
    if (deploymentId) {
        missionsCall({deploymentId});
    }
  },[])

  
  
  return(<>
    <LoadingGuard state={missionsState} >
        {missionsState.value &&
        <>
        <Stack direction={"column"} p={4} spacing={2}>
          {
            missionsState.value.map((mise:any)=>{
              const datum = new Timestamp(mise.datum.seconds, mise.datum.nanoseconds).toDate();
              const today = new Date();
              today.setDate(today.getDate() - 2); // zobrazit 2 dny po ukonceni nebo pokud se nastavi stav mise na dokonceny;
              if(mise.dokoncena == "ne" && datum > today ){
                return(
                <Link key={mise.id} href={`/deployment/${deploymentId}/${mise.id}`} style={{textDecoration:'none'}}>
                  <Grid container direction={"row"} alignItems={"center"} component={Paper} px={1} py={0.5}>
                    <Grid item xs={12} md={3}><Typography variant="body2">{mise.datum.toDate().toLocaleDateString("cs-CZ",{weekday:"long"})} {mise.datum.toDate().toLocaleDateString("cs-CZ")}</Typography></Grid>
                    <Grid item xs={10} md={8}><Typography variant="h5">{mise.nazev}</Typography></Grid>
                    <Grid item xs={1}>
                      <Link href={mise.briefing} target="__blank" title="Briefing" >
                        <IconButton><FileCopy /></IconButton>
                      </Link>
                    </Grid>  
                  </Grid>
                </Link>
                );
              }
            })
          }
        </Stack>
        </>
        }
    </LoadingGuard>
  </>)
}

