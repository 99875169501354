import { Typography, Stack, Box, Button } from "@mui/material";
import { cs } from "date-fns/locale";
import { useState } from "react";
import { FormContainer, TextFieldElement, DateTimePickerElement, CheckboxElement } from "react-hook-form-mui";
import DateFnsProvider from "../DateFnsProvider";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-private";
import { ReplaceSpecials } from "../helpers";
import LoaderButton from "../loaderButton";





async function SaveForm(data:any,deployId:string) {
  
  if (data.datum_od !== ""){
    data.datum_od = Timestamp.fromDate(data.datum_od)
  }else{
    data.datum_od = Timestamp.fromDate(new Date("01-01-1970"))
  }
  if (data.datum_do !== ""){
    data.datum_do = Timestamp.fromDate(data.datum_do)
  }else{
    data.datum_do = Timestamp.fromDate(new Date("01-01-1970"))
  }

  Object.keys(data).map((i)=>{
    if(data[i] === undefined) data[i] = "";
  })
  
  const memberRef = doc(db, 'deploymenty', deployId);
  setDoc(memberRef, data, { merge: true });
  
}

export default function DeploymentNew(props:any) {
  const [step,setStep] = [props.step, props.setStep];
  const [formSent,setFormSent] = useState(false);
  const [deployId,setDeployId] = useState('');

  if (formSent) {
    return(<>
      <Typography variant="h3">Odesláno</Typography>
    </>)
  }


  return(<>
      <FormContainer
        defaultValues={{datum_od: new Date(), datum_do: new Date(), oficialni:true}}
        onSuccess={(data)=>{SaveForm(data,deployId).then(()=>{setFormSent(true)})}}
      >
        <Stack direction={"column"} spacing={2} p={3}>
        <TextFieldElement label="Název Deploymentu" name="nazev" onInput={(t)=>{
          var target = t.target as HTMLInputElement;
          setDeployId(ReplaceSpecials(target.value))
          }}/>
        <Typography variant="body1">{deployId}</Typography>
        <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum od" name="datum_od" /></DateFnsProvider>
        <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum do" name="datum_do" /></DateFnsProvider>
        <TextFieldElement label="Mapa" name="mapa" />
        <TextFieldElement label="Odkaz na disk" name="disk" />
        <CheckboxElement label={"Oficiální?"} name={"oficialni"} />

        <Box sx={{display:"flex",justifyContent:"center"}}><LoaderButton type="submit" variant="outlined">Vložit!</LoaderButton></Box>
        </Stack>
      </FormContainer>
    </>)
}