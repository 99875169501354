import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../components/firebase-public";
import { useEffect } from "react";
import LoadingGuard from "../components/loadingGuard";
import { useSafeAsync } from "../components/useSafeAsync";
import { Grid, IconButton, Link, Paper, Stack, Typography } from "@mui/material";
import { FileOpen } from "@mui/icons-material";


async function GetMissions() {
  const date = new Date(); 
  date.setMinutes(date.getMinutes() - 15); // 15 minut i po zahajeni
  const missionsColl = query(collection(db, 'mise'), where("datum", ">=", date), orderBy("datum", "asc"))

  const missionsSnapshot = await getDocs(query(missionsColl));
  if (!missionsSnapshot.empty) {
    return missionsSnapshot.docs.map((doc) => {return {id:doc.id,...doc.data()}} );
  }
  
  return [];
}

export default function MissionsPage(props:any) {
  const [missionsCall, missionsState] = useSafeAsync<any,any>(GetMissions);

  useEffect(()=>{
    missionsCall({});
  },[])
  
  
  return(<>
    <LoadingGuard state={missionsState} >
        {missionsState.value &&
        <>
        <Stack direction={"column"} p={4} spacing={2}>
          {missionsState.value.map((mise:any)=>{
              return(
              <Link key={mise.id} href={`/mise/${mise.id}`} style={{textDecoration:'none'}}>
                <Grid container direction={"row"} alignItems={"center"} component={Paper} px={1} py={0.5}>
                  <Grid item xs={12} md={2} ><Typography variant="body2">{mise.datum.toDate().toLocaleDateString("cs-CZ",{weekday:"long"})} {mise.datum.toDate().toLocaleDateString("cs-CZ")}</Typography></Grid>
                  <Grid item xs={10} md={9} ><Typography variant="h5">{mise.nazev}</Typography></Grid>
                  <Grid item xs={1}>
                    <Link href={mise.briefing} target="__blank" title="Briefing" >
                      <IconButton><FileOpen /></IconButton>
                    </Link>
                  </Grid>  
                </Grid>
              </Link>
              );
            })
          }
        </Stack>
        </>
        }
    </LoadingGuard>
  </>)
}

