import { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";


export type AsyncState<R> = {
  isLoading: boolean,
  isCalled: boolean,
  error: string|null,
  value: R | null,
}

//generics
export const useSafeAsync = <P, R>(asyncFunction: (param?: P) => Promise<R>): [(param: P) => Promise<R | null>, AsyncState<R>] => {
  const [value, setValue] = useState<R | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const navigate = useNavigate();

  // useCallback ensures useEffect is not called on every render, but only if asyncFunction changes.
  const call = useCallback(
    async (param: P) => {
      //console.log("Rebuilding call", asyncFunction);
      setError(null);
      setIsLoading(true);
      setValue(null);
      setIsCalled(true);

      try {
        const result = await asyncFunction(param);
        setValue(result);
        //@ts-ignore
        if(result && result.message){setError("Chyba načítání obsahu. Zkuste to prosím později");}
        return result;
      } catch (e:any) {
        console.log('Async error: ', e);
        setError("Chyba načítání obsahu. Zkuste to prosím později")
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [asyncFunction, navigate]
  );

  const state = useMemo(
    () => ({
      value,
      isLoading,
      isCalled,
      error
    }),
    [isLoading, isCalled, value, error]
  );
  return [call, state];
};
